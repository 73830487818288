import {
  IonContent,
  IonPage,
  IonHeader,
  IonText,
  IonInput,
  IonItem,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonImg,
  IonIcon,
} from "@ionic/react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { addUser } from "../../../redux/slices/registerUser/registerUserSlice";
import "./register.css";
import { useState } from "react";
import axios from "axios";
import logo from "../../../assets/FormulaireAssets/2.png";
import {ChangeLogin, currentUser} from "../../../redux/slices/currentUser/currentUserSlice";
import { updateForm } from "../../../redux/slices/formUpdate/formUpdateSlice";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import FooterForm from "../../Reusable/FooterForm";

const Register = () => {
  const [data, setData] = useState({
    lastName: null,
    email: null,
    password: null,
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);

  const [updateRegister, setUpdateRegister] = useState({
    updateRegister: false,
  });

  const handleChangeDataRegister = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handlePassword = (e) => {
    if(e.target.name === "password"){
      if(e.target.value.length >= 1 && e.target.value.length < 5){
        setPasswordValid(true)
      }else{
        
        setData({ ...data, password: e.target.value });
        setPasswordValid(false)
      }
    }
  }


  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e) => {
    dispatch(addUser(data));
    axios
      .post(`${process.env.REACT_APP_STAGING_V2}/user/create`, {
        debug: false,
        data,
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(currentUser(res.data.Data[0]));
          setUpdateRegister({ ...updateForm, updateRegister: true });
          dispatch(ChangeLogin({isLoggin: false , isRegister : true}))
          dispatch(updateForm(updateRegister));
          history.push("/typeofrun");
        }
      })
      .catch((err) => console.error(err));
  };




  return (
    <IonPage>

      <IonContent className="register_global content_form" class="scroll-content">

        <div className="register">

          <img src={logo} alt={"logo register"} className={"register_img"}/>

          <div className={"register_title_box"}>
            <h1 className={"register_title_bienvenue title_form_fredoka"}>Bienvenue</h1>
            <h1 className={"register_title_flit title_form_fredoka"}>chez Flit Run !</h1>
          </div>
          <p className={'register_subtitle'}>Prépare ta prochaine course avec ton plan d’entraînement individualisé. Démarre avec 1 mois gratuit, puis 7.99€/mois.</p>

          {
            passwordValid ? <p className={"register_passwordValid"}>Ton mot de passe est trop court (5 caractères minimum)</p> :""
          }

          <ul className={"register_list"}>
            <li className={"register_list_item"}>
              <input onChange={(e)=>handleChangeDataRegister(e)} name={'lastName'} placeholder={"Prénom"} className={"register_list_input"}/>
            </li>
            <li className={"register_list_item"}>
              <input type={"email"} onChange={(e)=>handleChangeDataRegister(e)} name={"email"} placeholder={"Adresse e-mail"} className={"register_list_input"}/>
            </li>
            <li className={"register_list_item"}>
              <label className={"register_list_input"}>
                <input name={"password"} className={"register_list_input_password"} onChange={(e)=>handlePassword(e)} type={showPassword ? "text" : "password"} placeholder={"Mot de passe"}  />{showPassword ? <IonIcon className={"register_list_input_icon"} onClick={()=>setShowPassword(!showPassword)} icon={eyeOutline}/> :<IonIcon  className={"register_list_input_icon"} onClick={()=>setShowPassword(!showPassword)} icon={eyeOffOutline}/>}
              </label>

            </li>
          </ul>
        </div>

        <FooterForm text={"Commencer"} onClick={handleSubmit}/>

        <p className={"register_toconnexion"} onClick={()=>history.push("/connexion")}>J'ai déja un compte</p>
      </IonContent>
    </IonPage>
  );
};

export default Register;
