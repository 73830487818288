import axios from "axios"
import {GetisVip} from "../../redux/slices/currentUser/currentUserSlice";
import moment from "moment";
import ModalNotVip from "../../components/Reusable/ModalNotVip";
import {useState} from "react";
import {ApiError} from "../Error/ApiError";


// APi calls for check the VIP status
export const IsVipOrNot = (data) => async (dispatch) => {
    await axios.post(`${process.env.REACT_APP_STAGING_V2}/payment/verifyPayment`, {
        debug: false, data: {
            userId: data.id,
        },
    }, {
        headers: {
            Authorization: data.token,
        }
    }).then(res => {

        //  status = 0, it's not subscribe
        //  status = 1, it's subscriber in trial period
        //  status = 2, is VIP

        // update the state in redux

        if (res.data.Data[0].status === 0) {
            dispatch(GetisVip({isVip: false, isTrialPeriod: false}))
        }
        if (res.data.Data[0].status === 2) {
            dispatch(GetisVip({isVip: true, isTrialPeriod: false}))
        }
        if (res.data.Data[0].status === 1) {
            dispatch(GetisVip({isVip: true, isTrialPeriod: true}))
        }


    }).catch(e => {

            ApiError(e);

        }
    )
}