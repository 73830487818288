import {
    IonContent,
    IonInput,
    IonItem,
    IonPage,
    IonToolbar,
    IonBackButton,
    IonButtons,
    IonHeader,
    IonButton,
    IonImg,
    IonSpinner,
    IonAlert,
    IonIcon,
    IonText,
} from '@ionic/react';
import {useState} from 'react';
import './connexion.css';
import {Link, useHistory} from 'react-router-dom';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {eyeOffOutline, eyeOutline} from 'ionicons/icons';
import {
    ChangeLogin,
    currentUser,
} from '../../../redux/slices/currentUser/currentUserSlice';
import logo from '../../../assets/FormulaireAssets/3.png';
import {getTraining} from '../../../redux/slices/trainingData/trainingDataSlice';
import {getGoal} from '../../../redux/slices/goals/goalSlice';
import {updateDisponibility} from '../../../redux/slices/disponibility/disponibility';
import {defaultValue} from "../../../redux/slices/trainingData/trainingDataSlice";
import FooterForm from "../../Reusable/FooterForm";
import {IsVipOrNot} from "../../../API/User/VipOrNot";
import {Capacitor} from "@capacitor/core";
import {InAppPurchase2 as iap} from "@awesome-cordova-plugins/in-app-purchase-2";


function Connexion() {
    const history = useHistory();
    const user = useSelector((state) => state.currentUser);
    const [badData, setBadData] = useState(false);
    const [loginData, setLoginData] = useState();
    const [userToken, setUserToken] = useState('');
    const [errorEmail, setErrorEmail] = useState("")
    const [login, setLogin] = useState({
        email: "",
        password: "",
    });
    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const [showAlert, setShowAlert] = useState(false);

    const [spin, setSpin] = useState(false);
    const dispatch = useDispatch();

    const handlePassword = (e) => {
        setLogin({...login, [e.target.name]: e.target.value});

    };

    const handleEmail = (e) => {
        const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

        if (regex.test(e.target.value)) {
            setLogin({...login, [e.target.name]: e.target.value});
        } else {
            setErrorEmail("Ton email n'est pas dans le bon format !")
        }
    };

    const handleSubmit = (e) => {

        setBadData(false);
        setSpin(true);

        axios
            .post(`${process.env.REACT_APP_STAGING_V2}/user/login`, {
                debug: false,
                data: {
                    email: login.email,
                    password: login.password,
                },
                args: {},
            })
            .then((response) => {
                dispatch(defaultValue())
                dispatch(currentUser(response.data.Data[0]));


                if (response.status === 200) {
                    axios.post(`${process.env.REACT_APP_STAGING_V2}/user/validated`, {
                        debug: false,
                        data: {
                            userId: response.data.Data[0].id
                        },
                        arg: {}
                    }).then(res => {


                        if (res.status === 200) {
                            if (res.data.Data[0].disponibility === false) {

                                history.push("/disponibilityWeek")
                                if (res.data.Data[0].goals === false) {


                                    history.push("/typeOfRun")
                                } else {

                                    if (res.data.Data[0].partner === false) {

                                        history.push("/connect")
                                    } else {

                                        if (res.data.Data[0].novice !== undefined && res.data.Data[0].novice.type === false) {

                                            history.push("/noviceLevel")

                                        } else {
                                            history.push("/tab1")
                                        }

                                    }
                                }
                            } else {
                                history.push('/tab1')
                                setSpin(false);

                            }

                        }

                    }).catch(e => {
                        alert(`${e}`)
                    })
                }


            })
            .catch((err) => {
                setBadData(true);
                setSpin(false);
                console.log(err)
            });

    };

    return (
        <IonPage>
            <IonContent className="connexion_global content_form ">

                <div className="connexion">

                    <img src={logo} alt={"logo connexion"} className={"connexion_img"}/>

                    <div className={"connexion_title_box"}>
                        <h1 className={"connexion_title_bienvenue title_form_fredoka"}>Me connecter</h1>
                        <h1 className={"connexion_title_flit title_form_fredoka"}>à Flit Run !</h1>
                    </div>
                    {
                        badData ? <p className={"connexion_passwordValid"}>Ton mot de passe ou ton email est incorrect
                            !</p> : ""
                    }


                    <ul className={"connexion_list"}>

                        <li className={"connexion_list_item"}>
                            <input type={"email"} onChange={(e) => handleEmail(e)} name={"email"}
                                   placeholder={"Adresse e-mail"}
                                   className={"connexion_list_input"}/>
                        </li>
                        <li className={"connexion_list_item"}>
                            <label className={"connexion_list_input"}>
                                <input name={"password"} className={"connexion_list_input_password"}
                                       onChange={(e) => handlePassword(e)} type={showPassword ? "text" : "password"}
                                       placeholder={"Mot de passe"}/>{showPassword ?
                                <IonIcon className={"connexion_list_input_icon"}
                                         onClick={() => setShowPassword(!showPassword)} icon={eyeOutline}/> :
                                <IonIcon className={"connexion_list_input_icon"}
                                         onClick={() => setShowPassword(!showPassword)} icon={eyeOffOutline}/>}
                            </label>

                        </li>

                    </ul>

                    <FooterForm text={spin ? <IonSpinner name={"crescent"}/> : "Se connecter"} onClick={handleSubmit}/>

                    <p className={"connexion_toregister"} onClick={() => history.push("/register")}>Je n'ai pas de
                        compte</p>
                </div>


                {showAlert ? (
                    <IonAlert
                        mode={Capacitor.getPlatform() === 'ios' ? "ios" : "md"}
                        isOpen={showAlert}
                        onDidDismiss={() => {
                            setShowAlert(false);
                            dispatch(defaultValue())
                            history.push('/');
                        }}
                        header="Oops !"
                        subHeader="Une erreur est survenue"
                        message="Réessayer Ulterieurement"
                        buttons={['Fermer']}
                    />
                ) : (
                    ''
                )}
            </IonContent>
        </IonPage>
    );
}

export default Connexion;
