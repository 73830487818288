import {IonApp, setupIonicReact} from "@ionic/react";
import {IonReactRouter} from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import Routes from "./routes/routes";
import {SplashScreen} from "@capacitor/splash-screen";
import {StatusBar, Style} from '@capacitor/status-bar';
import {useEffect} from "react";
import {isEmpty} from "./Utils/Utils";
import moment from "moment";
import {getShowModal, getTraining} from "./redux/slices/trainingData/trainingDataSlice";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {ChangeLogin} from "./redux/slices/currentUser/currentUserSlice";
import {initInAppPurchase} from "./components/App/Profil/shops";
import {store} from '../src/redux/redux';

setupIonicReact();


const App = () => {



    initInAppPurchase();
    StatusBar.setStyle({style: Style.Light})
    return (
        <IonApp>
            <IonReactRouter>
                <Routes/>
            </IonReactRouter>
        </IonApp>
    );
};

export default App;
