import React, {useState} from 'react';
import {IonButton, IonCheckbox, IonContent, IonLabel, IonModal, IonSpinner, IonText, isPlatform} from "@ionic/react";
import {isEmpty} from "../../../../Utils/Utils";
import moment from "moment/moment";
import {useHistory} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {ChangeLogin} from "../../../../redux/slices/currentUser/currentUserSlice";
import {getTraining} from "../../../../redux/slices/trainingData/trainingDataSlice";
import {updateDisponibility} from "../../../../redux/slices/disponibility/disponibility";
import {PlanGenerate} from "../../../../API/Training/PlanGenerate";
import {GetDisponibility} from "../../../../API/Disponibility/GetDisponibility";

const ProfilModal = ({showModal, setShowModal, currentDisponibility, numberOfRaces}) => {

    const history = useHistory()
    const [spin, setSpin] = useState(false)
    const dispatch = useDispatch()
    const currentUser = useSelector(state => state.currentUser)
    const training = useSelector(state => state.training);


    const [
        practice_running_time_available_by_week,
        setPractice_running_time_available_by_week,
    ] = useState(numberOfRaces);
    const week = training[0].Plan.TemporalPlanId

    const handleCountUp = () => {
        setPractice_running_time_available_by_week(
            practice_running_time_available_by_week + 1
        );
        if (practice_running_time_available_by_week >= 7) {
            return setPractice_running_time_available_by_week(
                practice_running_time_available_by_week
            );
        }
    };
    const handleCountDown = () => {
        setPractice_running_time_available_by_week(
            practice_running_time_available_by_week - 1
        );
        if (practice_running_time_available_by_week <= 1) {
            return setPractice_running_time_available_by_week(
                practice_running_time_available_by_week
            );
        }
    };


    const handleSubmit = () => {
        setSpin(true)
        axios
            .post(
                `${process.env.REACT_APP_STAGING_V2}/disponibility/update`,
                {
                    debug: false,
                    data: {
                        numberOfRaces: practice_running_time_available_by_week.toString(),
                        id: currentDisponibility.id,
                        userId: currentUser.id,
                        monday: currentDisponibility.monday,
                        tuesday: currentDisponibility.tuesday,
                        wednesday: currentDisponibility.wednesday,
                        thursday: currentDisponibility.thursday,
                        friday: currentDisponibility.friday,
                        saturday: currentDisponibility.saturday,
                        sunday: currentDisponibility.sunday,
                        week: week,
                    },
                },
                {
                    headers: {
                        Authorization: currentUser.token,
                    },
                }
            )
            .then(async (res) => {
                if (res.status === 200) {
                    setSpin(false)
                    setShowModal(false)
                    await dispatch(GetDisponibility(currentUser))

                    await dispatch(PlanGenerate(currentUser))


                }
            })
            .catch((err) => console.error(err));
    };


    return (


        <div className="profilModal">

            {
                !isEmpty(currentDisponibility)  && (
                    <>

                        <div className="profil_modal_content">
                            <IonButton
                                className="weekOfRaces_button"
                                onClick={handleCountDown}
                            >
                                -
                            </IonButton>

                            <h5

                                className="profil_modal_text">
                                {practice_running_time_available_by_week}


                            </h5>

                            <IonButton className="weekOfRaces_button" onClick={handleCountUp}>
                                +
                            </IonButton>
                        </div>

                        <IonButton onClick={handleSubmit} className={"profil_modal_button"}>
                            {spin ? <IonSpinner name="crescent"/> : "Modifier"}
                        </IonButton>
                    </>
                )
            }
        </div>

    );
};

export default ProfilModal;