import {IonText, IonIcon, IonButton, IonAlert, IonLoading} from "@ionic/react";
import "./profil.css";
import {useEffect, useState} from "react";
import {buyPackage, initInAppPurchase} from "./shops"
import {useSelector} from "react-redux";
import {Capacitor} from "@capacitor/core";

const Shop = () => {

    const [selectedPack, setSelectedPack] = useState(0);
    const shop = useSelector((state) => state.shop);
    const subStatus = useSelector((state) => state.subStatus);
    const currentUser = useSelector(state => state.currentUser)

    const pK = Object.keys(shop.packages).map(v => parseInt(v));
    const moreDays = () => setSelectedPack(pK[selectedPack !== 0 ? pK.indexOf(selectedPack) + 1 : 0] || 0);
    const lessDays = () => setSelectedPack(
        pK[pK.indexOf(selectedPack) - 1] !== undefined ? pK.indexOf(selectedPack) - 1 : 0
    );
    const buyDays = () => {
        if (selectedPack > 0) {
            buyPackage(selectedPack);
            setSelectedPack(0);
        }
    };
    const [showLoading, setShowLoading] = useState(false)

    return (
        <div>
            <ul className="profil_info_list">
                <li>
                    <div className="strava_item_content">
                    </div>
                </li>
                <li>
                    <div className="strava_item_content">

                        <button className="btn_buy_x_day" onClick={() => {
                            buyPackage("flit1m")
                            setShowLoading(true)

                        }}>S'abonner (7.99€/mois)
                        </button>

                    </div>
                </li>

            </ul>
            <IonLoading
                cssClass='my-custom-class'
                mode={Capacitor.getPlatform() === "ios" ? "ios" : "md"}
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Chargement...'}
                duration={5000}
            />
        </div>
    );
}

export default Shop;
