import React, {useEffect, useState} from 'react';
import {
    IonButton,
    IonCheckbox,
    IonContent, IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonModal, IonSpinner,
    IonText,
    isPlatform
} from "@ionic/react";
import moment from "moment";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {defaultDisponibility} from "../../../../redux/slices/disponibility/disponibility";
import {isEmpty} from "../../../../Utils/Utils";
import {ChangeLogin} from "../../../../redux/slices/currentUser/currentUserSlice";
import {emptyTraining, getTraining} from "../../../../redux/slices/trainingData/trainingDataSlice";
import '../../NewPlan/WeekBilan/weekBilanNumberOfRaces.css'
import {addOutline, remove} from "ionicons/icons";
import {forEachTraining} from "../../../../API/Training/GetTraining";

const FabContent = ({
                        showModal,
                        setShowModal,
                        currentDisponibility,
                        currentUser,
                        link,
                        numberOfRaces,
                        training,
                        setTraining
                    }) => {

    const history = useHistory()
    const [spin, setSpin] = useState(false)
    const dispatch = useDispatch()

    const [disponibilityData, setDisponibilityData] = useState({
        monday: currentDisponibility.disponibility.monday,
        tuesday: currentDisponibility.disponibility.tuesday,
        wednesday: currentDisponibility.disponibility.wednesday,
        thursday: currentDisponibility.disponibility.thursday,
        friday: currentDisponibility.disponibility.friday,
        saturday: currentDisponibility.disponibility.saturday,
        sunday: currentDisponibility.disponibility.sunday,
    });


    const [
        practice_running_time_available_by_week,
        setPractice_running_time_available_by_week,
    ] = useState(numberOfRaces);
    const week = training[0].Plan.TemporalPlanId


    const handleChecked = (e) => {
        setDisponibilityData({
            ...disponibilityData,
            [e.target.name]: e.target.checked,
        });
    };
    const handleCountUp = () => {
        setPractice_running_time_available_by_week(
            practice_running_time_available_by_week + 1
        );
        if (practice_running_time_available_by_week >= 7) {
            return setPractice_running_time_available_by_week(
                practice_running_time_available_by_week
            );
        }
    };
    const handleCountDown = () => {
        setPractice_running_time_available_by_week(
            practice_running_time_available_by_week - 1
        );
        if (practice_running_time_available_by_week <= 1) {
            return setPractice_running_time_available_by_week(
                practice_running_time_available_by_week
            );
        }
    };


    const handleSubmit = () => {
        setSpin(true)
        axios
            .post(
                `${process.env.REACT_APP_STAGING_V2}/disponibility/update`,
                {
                    debug: false,
                    data: {
                        numberOfRaces: practice_running_time_available_by_week.toString(),
                        id: currentDisponibility.id,
                        userId: currentDisponibility.userId,
                        monday: disponibilityData.monday,
                        tuesday: disponibilityData.tuesday,
                        wednesday: disponibilityData.wednesday,
                        thursday: disponibilityData.thursday,
                        friday: disponibilityData.friday,
                        saturday: disponibilityData.saturday,
                        sunday: disponibilityData.sunday,
                        week: week,
                    },
                },
                {
                    headers: {
                        Authorization: currentUser.token,
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {

                    axios
                        .post(
                            `${process.env.REACT_APP_STAGING_V2}/plan/generate`,
                            {
                                debug: false,
                                data: {
                                    id: currentUser.id,
                                },
                            },
                            {
                                headers: {
                                    Authorization: currentUser.token,
                                },
                            }
                        )
                        .then((res) => {
                            if (res.status === 200) {

                               dispatch(forEachTraining(currentUser))
                                setSpin(false)
                                setShowModal(false)
                            }
                            ;


                        })
                        .catch((error) => {
                            throw error;
                        });
                }
            })
            .catch((err) => console.error(err));
    };

    return (
        <IonModal
            isOpen={showModal}
            initialBreakpoint={isPlatform("desktop") ? 1 : 0.6}
            className="fab_button_modal"
            onDidDismiss={() => setShowModal(false)}
        >
            <IonContent className="app_content" fullscreen>
                <div className="fab_button_content">

                    {
                        !isEmpty(currentDisponibility) && (
                            <>

                                <IonText>
                                    <h4 className="fab_button_content_title">
                                        Modifier mes disponibilités :{" "}
                                    </h4>
                                </IonText>

                                <div className="weekOfRaces_globalButton fab_races">
                                    <div className="howManyTimes_globalButton">
                                        <div
                                            className="howManyTimes_button"
                                            onClick={handleCountDown}
                                        >
                                            <IonIcon icon={remove}/>
                                        </div>
                                        <input className="howManyTimes_input fab_input" readOnly
                                               value={practice_running_time_available_by_week}/>
                                        <div
                                            className="howManyTimes_button"
                                            onClick={handleCountUp}
                                        >
                                            <IonIcon icon={addOutline}/>
                                        </div>
                                    </div>
                                </div>
                                <ul className="disponibility_list fab_dispo_list">
                                    <li className="disponibility_list_item">
                                        <IonLabel className="disponibility_list_label">L</IonLabel>
                                        <span
                                            className={
                                                disponibilityData.monday
                                                    ? "disponibility_checkbox_box_checked"
                                                    : "disponibility_checkbox_box"
                                            }
                                        >
                  <IonCheckbox
                      onIonChange={(e) => {
                          handleChecked(e);
                      }}
                      type="checkbox"
                      name="monday"
                      className="disponibility_list_item_checkbox"
                      defaultChecked={disponibilityData.monday}
                      checked={disponibilityData.monday}
                      disabled={moment().day() > 1}


                  />
                </span>
                                    </li>
                                    <li className="disponibility_list_item">
                                        <IonLabel className="disponibility_list_label">M</IonLabel>
                                        <span
                                            className={
                                                disponibilityData.tuesday
                                                    ? "disponibility_checkbox_box_checked"
                                                    : "disponibility_checkbox_box"
                                            }
                                        >
                  <IonCheckbox
                      onIonChange={(e) => {
                          handleChecked(e);
                      }}
                      type="checkbox"
                      name="tuesday"
                      className="disponibility_list_item_checkbox"
                      defaultValue={disponibilityData.tuesday}
                      checked={disponibilityData.tuesday}
                      disabled={moment().day() > 2}

                  />
                </span>
                                    </li>
                                    <li className="disponibility_list_item">
                                        <IonLabel className="disponibility_list_label">M</IonLabel>
                                        <span
                                            className={
                                                disponibilityData.wednesday
                                                    ? "disponibility_checkbox_box_checked"
                                                    : "disponibility_checkbox_box"
                                            }
                                        >
                  <IonCheckbox
                      onIonChange={(e) => {
                          handleChecked(e);
                      }}
                      type="checkbox"
                      name="wednesday"
                      className="disponibility_list_item_checkbox"
                      defaultValue={disponibilityData.wednesday}
                      checked={disponibilityData.wednesday}
                      disabled={moment().day() > 3}

                  />
                </span>
                                    </li>
                                    <li className="disponibility_list_item">
                                        <IonLabel className="disponibility_list_label">J</IonLabel>
                                        <span
                                            className={
                                                disponibilityData.thursday
                                                    ? "disponibility_checkbox_box_checked"
                                                    : "disponibility_checkbox_box"
                                            }
                                        >
                  <IonCheckbox
                      onIonChange={(e) => {
                          handleChecked(e);
                      }}
                      type="checkbox"
                      name="thursday"
                      className="disponibility_list_item_checkbox"
                      checked={disponibilityData.thursday}
                      defaultValue={disponibilityData.thursday}
                      disabled={moment().day() > 4}

                  />
                </span>
                                    </li>
                                    <li className="disponibility_list_item">
                                        <IonLabel className="disponibility_list_label">V</IonLabel>
                                        <span
                                            className={
                                                disponibilityData.friday
                                                    ? "disponibility_checkbox_box_checked"
                                                    : "disponibility_checkbox_box"
                                            }
                                        >
                  <IonCheckbox
                      onIonChange={(e) => {
                          handleChecked(e);
                      }}
                      type="checkbox"
                      name="friday"
                      className="disponibility_list_item_checkbox"
                      defaultChecked={disponibilityData.friday}
                      checked={disponibilityData.friday}
                      disabled={moment().day() > 5}

                  />
                </span>
                                    </li>
                                    <li className="disponibility_list_item">
                                        <IonLabel className="disponibility_list_label">S</IonLabel>
                                        <span
                                            className={
                                                disponibilityData.saturday
                                                    ? "disponibility_checkbox_box_checked"
                                                    : "disponibility_checkbox_box"
                                            }
                                        >
                  <IonCheckbox
                      onIonChange={(e) => {
                          handleChecked(e);
                      }}
                      type="checkbox"
                      name="saturday"
                      className="disponibility_list_item_checkbox"
                      defaultChecked={disponibilityData.saturday}
                      checked={disponibilityData.saturday}
                      disabled={moment().day() > 6}

                  />
                </span>
                                    </li>
                                    <li className="disponibility_list_item">
                                        <IonLabel className="disponibility_list_label">D</IonLabel>
                                        <span
                                            className={
                                                disponibilityData.sunday
                                                    ? "disponibility_checkbox_box_checked"
                                                    : "disponibility_checkbox_box"
                                            }
                                        >
                  <IonCheckbox
                      onIonChange={(e) => {
                          handleChecked(e);
                      }}
                      type="checkbox"
                      name="sunday"
                      className="disponibility_list_item_checkbox"
                      defaultValue={disponibilityData.sunday}
                      checked={disponibilityData.sunday}
                  />
                </span>
                                    </li>
                                </ul>
                                <IonButton onClick={handleSubmit} className="disponibility_button">
                                    {spin ? <IonSpinner name="crescent"/> : "Modifier"}
                                </IonButton>
                            </>
                        )
                    }
                </div>
            </IonContent>
        </IonModal>
    );
};

export default FabContent;