import {
  configureStore,
  applyMiddleware,
  combineReducers,
} from '@reduxjs/toolkit';
import { composeWithDevTools } from '@redux-devtools/extension';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { garminSlice } from './slices/strava/stravaSlice';
import {
  currentUserSlice,
  isLogginSlice,
  logOutSlice,
  noviceSlice,
  VIPSlice
} from './slices/currentUser/currentUserSlice';
import { registerUserSlice } from './slices/registerUser/registerUserSlice';
import { shopSlice} from "./slices/shop/shopSlice";
import {
  countSlice,
  getnewGoal, newGoalsSlice,
  trainingDataSlice,
  trainingShowModalSlice
} from './slices/trainingData/trainingDataSlice';
import { disponibilitySlice } from './slices/disponibility/disponibility';
import { updateFormSlice } from './slices/formUpdate/formUpdateSlice';
import { newPlanSlice } from './slices/newPlan/newPlanSlice';
import {goalSlice, goalTrySlice} from './slices/goals/goalSlice';
import {fromPedagogieSlice, pedagogieSlice} from "./slices/pedagogie/pedagogie.slice";

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['users', 'shop', 'subStatus', 'newPlan', 'updateForm'],
};
const reducers = combineReducers({
  users: registerUserSlice.reducer,
  garmin: garminSlice.reducer,
  currentUser: currentUserSlice.reducer,
  shop: shopSlice.reducer,
  training: trainingDataSlice.reducer,
  disponibility: disponibilitySlice.reducer,
  login: isLogginSlice.reducer,
  updateForm: updateFormSlice.reducer,
  newPlan: newPlanSlice.reducer,
  goal: goalSlice.reducer,
  logOut: logOutSlice.reducer,
  count : countSlice.reducer,
  pedagogie : pedagogieSlice.reducer,
  fromPedagogie: fromPedagogieSlice.reducer,
  showModal: trainingShowModalSlice.reducer,
  novice : noviceSlice.reducer,
  newgoal: newGoalsSlice.reducer,
  isVIP: VIPSlice.reducer,
  persistConfig,
});

const rootReducers = (state, action) => {
  if (action.type === 'logOut/logOut') {

    storage.removeItem('persist:root')

    return reducers(undefined, action);
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: [thunk],
});

export const persistor = persistStore(store);
