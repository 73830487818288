import {IonContent, IonImg, IonPage, IonText, IonSpinner} from "@ionic/react";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import logoFlit from "../../../../assets/FormulaireAssets/Ola como estas.gif";
import {emptyTraining, getTraining} from "../../../../redux/slices/trainingData/trainingDataSlice";
import {ChangeLogin} from "../../../../redux/slices/currentUser/currentUserSlice";
import "./formSuccess.css";
import {updateDisponibility} from "../../../../redux/slices/disponibility/disponibility";
import {isEmpty} from "../../../../Utils/Utils";
import formSuccess from "./FormSuccess";
import {forEachTraining} from "../../../../API/Training/GetTraining";

const FormSuccess = ({user}) => {
    const history = useHistory();
    const isLoggin = useSelector(state => state.login)


    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.currentUser);
    const [test, setTest] = useState({
        text1: true,
        text2: false,
        text3: false,
        text4: false,
    });





    useEffect(() => {

        dispatch(forEachTraining(currentUser))
        setTimeout(() => {
            if (test.text1 === true) {
                setTest({
                    ...test,
                    text1: false,
                    text2: true,
                    text3: false,
                    text4: false,
                });
            }
            if (test.text2 === true) {
                setTest({
                    ...test,
                    text1: false,
                    text2: false,
                    text3: true,
                    text4: false,
                });
            }
            if (test.text3 === true) {
                setTest({
                    ...test,
                    text1: false,
                    text2: false,
                    text3: false,
                    text4: true,
                });
            }
            if (test.text4 === true) {
                setTest({
                    ...test,
                    text1: true,
                    text2: false,
                    text3: false,
                    text4: false,
                });
            }

        }, 5000);



    }, [test]);

    return (

        <div className="formSuccess">
            <IonImg className="formSuccess_logo img_form" src={logoFlit}/>

            <div
                className={
                    test.text1 ? "formSuccess_textContent" : "formSuccess_none"
                }
            >
                <IonText className="formSuccess_subTitle">
                    <h2> Récupération de tes données…</h2>
                </IonText>
                <IonText>
                    <p>
                        {" "}
                        On importe toutes tes courses pour savoir ce dont tu es capable.
                    </p>
                </IonText>
            </div>

            <div
                className={
                    test.text2 ? "formSuccess_textContent" : "formSuccess_none"
                }
            >
                <IonText className="formSuccess_subTitle">
                    <h2> Analyse de ton profil…</h2>
                </IonText>
                <IonText>
                    <p> On évalue ton niveau et ton potentiel.</p>
                </IonText>
            </div>
            <div
                className={
                    test.text3 ? "formSuccess_textContent" : "formSuccess_none"
                }
            >
                <IonText className="formSuccess_subTitle">
                    <h2> Structuration du plan…</h2>
                </IonText>
                <IonText>
                    <p> On prépare ton calendrier en fonction de ton objectif.</p>
                </IonText>
            </div>
            <div
                className={
                    test.text4 ? "formSuccess_textContent" : "formSuccess_none"
                }
            >
                <IonText className="formSuccess_subTitle">
                    <h2> Génération de la première semaine…</h2>
                </IonText>
                <IonText>
                    <p> Chaque semaine des séances variées à découvrir !</p>
                </IonText>
            </div>
        </div>

    );
};

export default FormSuccess;
