import React, {useEffect, useState} from "react";
import {
    IonIcon,
    IonFab,
    IonFabButton,
    IonModal,
    IonButton,
    IonContent,
    IonText,
    IonCheckbox,
    IonLabel,
    IonItem,
    IonInput,
} from "@ionic/react";
import {optionsOutline, calendarOutline} from "ionicons/icons";
import moment from "moment";
import "./fabButton.css";
import {useDispatch, useSelector} from "react-redux";
import {useIonAlert} from "@ionic/react";
import axios from "axios";
import {updateDisponibility} from "../redux/slices/disponibility/disponibility";
import {isPlatform} from "@ionic/react";
import {useHistory} from "react-router";
import FabButtonText from "../components/App/FabButtonContent/content/FabButtonText";
import FabContent from "../components/App/FabButtonContent/content/FabContent";
import {isEmpty} from "../Utils/Utils";
import dispoLogo from "../assets/menu/dispo.png"

const FabButton = ({showModal, setShowModal, link ,training,setTraining ,getData}) => {
    const [presentAlert] = useIonAlert();
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.currentUser);
    const currentDisponibility = useSelector((state) => state.disponibility);
    const [alert] = useIonAlert();
    const [dispo,setDispo]= useState([])


    const history = useHistory();
    const update = useSelector((state) => state.updateForm);


    const disponibilityData = async () => {
        await axios
            .post(
                `${process.env.REACT_APP_STAGING_V2}/disponibility/foreach`,
                {
                    debug: false,
                    filter: [
                        {
                            field: "userId",
                            comparator: "==",
                            operand: [[currentUser.id]],
                        },
                    ],
                    fields: [],
                },
                {
                    headers: {
                        Authorization: currentUser.token,
                    },
                }
            )
            .then((res) => {
                if (res.status === 200 ) {
                    setDispo({id: res.data.Data[0].id, disponibility: res.data.Data[0]})
                    dispatch(updateDisponibility({id: res.data.Data[0].id, disponibility: res.data.Data[0]}))
                    setShowModal(true)
                } else {
                    presentAlert({
                        header: 'Oops !',
                        subHeader: 'Désolé',
                        message: 'Il y a une problème avec tes disponibilité, réessaie plus tard !',
                        buttons: ['OK'],
                    })
                }
            })
            .catch((err) => {
            });
    }


    return (
        <div>

            <>
                <IonFab
                    vertical="bottom" horizontal="end" edge slot="fixed"
                    className="fab_button_content"
                >

                    <IonFabButton
                        onClick={disponibilityData}
                        className="tabBar_fab_button"
                    >
                        <IonIcon icon={calendarOutline} alt="disponibilité" className="icon_fabButton"/>
                    </IonFabButton>

                </IonFab>

                {
                    !isEmpty(dispo) && (
                        <FabContent currentDisponibility={dispo} currentUser={currentUser}
                                    showModal={showModal} setShowModal={setShowModal} link={link} training={training} setTraining={setTraining} numberOfRaces={parseInt(dispo.disponibility.numberOfRaces)} />)
                }


            </>

        </div>
    );
};

export default FabButton;
