import axios from "axios";
import {forEachTraining} from "./GetTraining";

export const PlanGenerate = (currentUser) => async (dispatch) => {
    await axios
        .post(
            `${process.env.REACT_APP_STAGING_V2}/plan/generate`,
            {
                debug: false,
                data: {
                    id: currentUser.id,
                },
            },
            {
                headers: {
                    Authorization: currentUser.token,
                },
            }
        )
        .then((res) => {
            if (res.status === 200) {

                dispatch(forEachTraining(currentUser))

            }
            ;


        })
        .catch((error) => {
            throw error;
        });
}
