import {
    IonContent,
    IonHeader,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonToolbar,
} from "@ionic/react";
import Home from "../components/App/Home/Home";
import TrainingWeekPlan from "../components/App/Home/trainingWeekPlan/TrainingWeekPlan";
import FabButton from "./FabButton";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "../Utils/Utils";
import {emptyTraining, getShowModal, getTraining} from "../redux/slices/trainingData/trainingDataSlice";
import axios from "axios";
import {ChangeLogin} from "../redux/slices/currentUser/currentUserSlice";
import chaussure from "../assets/menu/chaussure_bleu.png"
import {StatusBar, Style} from "@capacitor/status-bar";
import {emptyGoals} from "../redux/slices/goals/goalSlice";
import {forEachGoal} from "../API/Goal/GetGoal";
import {forEachTraining} from "../API/Training/GetTraining";
import logoBlanc from "../assets/pictoFlit/LOGO_FLIT_blanc2.png"
import moment from "moment";
import "./Tab1.css"
import {updateDisponibility} from "../redux/slices/disponibility/disponibility";
import {IsVipOrNot} from "../API/User/VipOrNot";


const Tab1 = () => {
    const training = useSelector(state => state.training)
    const showModal = useSelector(state => state.showModal)
    const [showModalFab, setShowModalFab] = useState(false)
    const link = "/"
    const [showDispo, setShowDispo] = useState(false)
    const user = useSelector((state) => state.currentUser);
    const isRegister = useSelector(state => state.login.isRegister)
    const isLoggin = useSelector(state => state.login.isLoggin)
    const dispatch = useDispatch()
    const goal = useSelector(state => state.goal)
    const [goalText, setGoalText] = useState("")
    const [date, setDate] = useState();


    useEffect(() => {

        dispatch(forEachTraining(user))
        setTimeout(() => {
            dispatch(emptyGoals())
            dispatch(forEachGoal(user))
        }, 500)

        // try {
        //     if (!isEmpty(training)) {
        //         const sunday = moment()
        //             .year(
        //                 parseInt(training[0].Plan.TemporalPlanId.toString().split("")[0] + "" + training[0].Plan.TemporalPlanId.toString().split("")[1] + "" + training[0].Plan.TemporalPlanId.toString().split("")[2] + "" + training[0].Plan.TemporalPlanId.toString().split("")[3])
        //             )
        //             .isoWeek(parseInt(training[0].Plan.TemporalPlanId.toString().split("")[4] + "" + training[0].Plan.TemporalPlanId.toString().split("")[5]))
        //             .endOf("week")
        //         if (moment().isoWeek() > sunday.isoWeek()) {
        //             setShowDispo(false)
        //         } else {
        //             setShowDispo(true)
        //         }
        //     }
        // } catch (e) {
        //     console.log(e)
        // }

    }, [user]);


    return (
        <IonPage>
            <IonHeader class="ion-no-border" mode="ios">

                <IonToolbar className="tab1_header">
                    <div className="tab1_content">
                        <img className="tab1_logo" src={logoBlanc} alt="logo"/>


                        <ul className="tab1_bar_content_list">
                            {
                                !isEmpty(training[0]) && (
                                    <>
                                        <li>
                                            <div className="tab1_bar_content_pourcent">
                                                <div className={"tab1_bar_content_pourcent_box"} style={{
                                                    width: `${!isEmpty(training[0].Plan.CycleInformation.progression_to_goal) && (
                                                        training[0].Plan.CycleInformation.progression_to_goal < 5 ? "8" :
                                                            training[0].Plan.CycleInformation.progression_to_goal)}%`,

                                                }}> <span className={"tab1_bar_content_pourcent_text"}>
                                            {!isEmpty(training[0].Plan.CycleInformation.progression_to_goal) && (Math.round(training[0].Plan.CycleInformation.progression_to_goal))}%
                                        </span></div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="tab1_bar_content">
                                <span
                                    className={!isEmpty(training[0].Plan.CycleInformation.progression_to_goal) && (
                                        training[0].Plan.CycleInformation.progression_to_goal < 5 ? "tab1_progressBar_round_empty" :
                                            "")}></span>
                                                {

                                                    !isEmpty(training) && (
                                                        <>
                                                            <div className="tab1_progressBar" style={{
                                                                width: `${!isEmpty(training[0].Plan.CycleInformation.progression_to_goal) && (
                                                                    training[0].Plan.CycleInformation.progression_to_goal < 5 ? "0" :
                                                                        training[0].Plan.CycleInformation.progression_to_goal)}%`,

                                                            }}><span
                                                                className={!isEmpty(training[0].Plan.CycleInformation.progression_to_goal) && (
                                                                    training[0].Plan.CycleInformation.progression_to_goal < 5 ? "" :
                                                                        "tab1_progressBar_round")}></span></div>


                                                        </>
                                                    )

                                                }


                                            </div>
                                        </li>
                                    </>)
                            }

                        </ul>
                        <div className={"tab1_progressBar_objectiv"}>
                            <div className={"tab1_progressBar_objectiv_icon_box"}>
                                <img className={"tab1_progressBar_objectiv_icon"} src={chaussure}
                                     alt={"logo_chaussure"}/>
                                <div>{!isEmpty(goal[0]) && (
                                    <>
                                        {
                                            goal[0].filter(f => f.main === true).map((f, index) => (
                                                <div key={index}>
                                                    {
                                                        index === 0 && (
                                                            <p>   {
                                                                f.name === "Semi-marathon" ? "21k" : f.name === "Marathon" ? "42k" : f.name === "Pas d'objectif" ? "?" : f.name
                                                            }
                                                            </p>
                                                        )
                                                    }
                                                </div>

                                            ))
                                        }


                                    </>
                                )}</div>
                            </div>

                            {
                                !isEmpty(goal[0]) && (
                                    <>
                                        {
                                            goal[0].filter(f => f.main === true).map((f, index) => (
                                                <div key={index}>
                                                    {
                                                        index === 0 && (
                                                            <p>{moment(f.date).format(('l'))}</p>
                                                        )
                                                    }
                                                </div>
                                            ))
                                        }
                                    </>
                                )
                            }

                            {/*     <p>{moment(goal.filter(f => f.main === true)[0].date).format(('l'))}</p>*/}
                        </div>
                    </div>


                </IonToolbar>
            </IonHeader>
            <IonContent className="app_content">

                <Home/>
            </IonContent>

            {
                !isEmpty(training) && (
                    <>
                        {
                            showModal.showModal === true ?
                                " " : <FabButton showModal={showModalFab} setShowModal={setShowModalFab} link={link}
                                                 training={training}
                                />
                        }


                    </>
                )
            }

        </IonPage>
    );
};

export default Tab1;
