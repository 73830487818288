import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import SkeletonTrainingWeek from "./SkeletonTrainingWeek/SkeletonTrainingWeek";
import TrainingWeekPlan from "./trainingWeekPlan/TrainingWeekPlan";

import moment from "moment/moment";
import axios from "axios";
import {getShowModal, getTraining} from "../../../redux/slices/trainingData/trainingDataSlice";
import {useHistory} from "react-router";
import FormSuccess from "../../Formulaire/FormContent/FormSuccess/FormSuccess";
import {ChangeLogin} from "../../../redux/slices/currentUser/currentUserSlice";
import {IonImg, IonText} from "@ionic/react";
import logoFlit from "../../../assets/FormulaireAssets/Ola como estas.gif";
import {isEmpty} from "../../../Utils/Utils";
import {trainingWeekPlan} from "../../../assets/db";
import FabButton from "../../../pages/FabButton";

const Home = () => {


    const countTraining = useSelector(state => state.count);

    const history = useHistory();

    const showModal = useSelector(state => state.showModal)
    const currentUser = useSelector((state) => state.currentUser);

    const user = useSelector((state) => state.currentUser);
    const isRegister = useSelector(state => state.login.isRegister)
    const isLoggin = useSelector(state => state.login.isLoggin)
    const [data, setData] = useState([])
    const [mounted, setMounted] = useState(false);
    const training = useSelector(state => state.training)
    const dispatch =useDispatch()



    return (
        <>
            {
                isRegister === true && isLoggin === false ?

                    <FormSuccess training={training}
                                 user={user}
                    />
                    :

                    <>
                        {
                            isEmpty(training)&&(
                                <SkeletonTrainingWeek />
                            )
                        }


                        {
                            (!isEmpty(training)) && (
                                <>

                                    <TrainingWeekPlan
                                        training={training}
                                        user={user}
                                        mounted={mounted}
                                        setMounted={setMounted}
                                        count={countTraining.value}

                                    />

                                </>

                            )

                        }




                    </>
            }

        </>
    )
}
export default Home;
