import React, {useEffect, useState} from 'react';
import {

    IonButton,
    IonContent, IonDatetime,
    IonHeader, IonIcon,
    IonModal,
    IonPage, IonSelect,
    IonToolbar, isPlatform,
    IonSelectOption, IonButtons, IonBackButton, IonText
} from "@ionic/react";

import FooterForm from "../../../../Reusable/FooterForm";
import {isEmpty} from "../../../../../Utils/Utils";
import * as IoIcons from "react-icons/io5";
import {chevronDown} from "ionicons/icons";
import moment from "moment/moment";
import './objectifInter.css'
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import logo from "../../../../../assets/FormulaireAssets/5.png"
import * as AiIcons from 'react-icons/ai'
import SkeletonObjectif from "../SkeletonObjectif/SkeletonObjectif";
import ModifyInter from "./modify/ModifyInter";
import Star from "../../../../App/Home/trainingWeekPlan/content/Star";
import {emptyGoals, getGoal} from "../../../../../redux/slices/goals/goalSlice";
import {forEachGoal} from "../../../../../API/Goal/GetGoal";
import {forEachTraining} from "../../../../../API/Training/GetTraining";

const ObjectifInter = () => {
    const [question, setQuestions] = useState();
    const [date, setDate] = useState(moment().format("YYYY-MM-DD"))
    const [distance, setDistance] = useState("")
    const [list, setList] = useState([])
    const [show, setShow] = useState(false)
    const [add, setAdd] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const history = useHistory()
    const user = useSelector(state => state.currentUser)
    const isModify = history.location.pathname
    const dispatch = useDispatch()
    const [spinner, setSpinner] = useState(false)


    useEffect(() => {

        try {
            axios
                .post(
                    `${process.env.REACT_APP_STAGING_V2}/goalType/find`,
                    {
                        debug: false,
                        filter: [],
                        fields: [],
                    },
                    {
                        headers: {
                            Authorization: user.token,
                        },
                    }
                )
                .then((res) => {
                    setQuestions(res.data);
                })
                .catch((err) => console.error(err));
        } catch (e) {
            console.log(e)
        }
    }, [user.token]);
    const handleChooseDate = (e) => {

        const {value} = e.target;
        setDate(moment(value).format("YYYY-MM-DD"))
    };

    const addTodo = async () => {

        const item = {
            listId: Math.floor(Math.random() * 1000),
            distance: question.Data.filter(f => f.id === distance).map(t => t.name),
            date: date,
            id: distance
        }
        if (!isEmpty(item.distance) && !isEmpty(item.date)) {
            setAdd(true)
            await setList(copy => [...copy, item])
            setDistance("")
            setDate(moment().format("YYYY-MM-DD"))
            setShow(false)
        }

        setShowModal(false)


    }
    const deleteTodo = (idList) => {
        const newArray = list.filter(item => item.listId !== idList)
        setList(newArray)


    }


    const handleClick = () => {

        setSpinner(true)

        if (list.length === 0) {

            if (isModify === "/modifyInterObj") {

                axios
                    .post(`${process.env.REACT_APP_STAGING_V2}/plan/generate`, {
                        debug: false, data: {
                            id: user.id,
                        },
                    }, {
                        headers: {
                            Authorization: user.token,
                        },
                    }).then(res => {
                    if (res.status == 200) {
                        dispatch(emptyGoals())
                        dispatch(forEachGoal(user))
                        setTimeout(() => {
                            dispatch(forEachTraining(user))
                            setSpinner(false)
                            history.push('/tab4')

                        }, 500)
                    }
                }).catch(e => console.log(e))

            } else {
                history.push('/connect')
            }

        } else {


            for (let i = 0; i < list.length; i++) {
                axios
                    .post(
                        `${process.env.REACT_APP_STAGING_V2}/goals/create`,
                        {
                            debug: false,
                            data: {
                                userId: user.id,
                                main: false,
                                goalType: list[i].id,
                                date: list[i].date.concat(" 00:00:00 +0000 UTC"),
                            },
                        },
                        {
                            headers: {
                                Authorization: user.token,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.status === 200) {

                            if (isModify === "/modifyInterObj") {
                                dispatch(emptyGoals())
                                dispatch(forEachGoal(user))
                                setTimeout(() => {
                                    setSpinner(false)
                                    history.push("/tab4")


                                }, 1000)
                            } else {
                                setTimeout(() => {
                                    setSpinner(false)


                                    history.push('/connect')


                                }, 1000)
                            }
                        }

                    })
                    .catch((err) => console.error(err));


            }


        }

    }


    return (
        <IonPage>
            {
                isModify === "/modifyInterObj" ? <IonHeader class="ion-no-border">
                    <IonToolbar className="modify_header" mode="ios">
                        <IonButtons className="day_buttons_header" slot="start">
                            <IonBackButton
                                defaultHref="/tab4"
                                text=""
                                className="headerBackButton"
                            />
                        </IonButtons>
                    </IonToolbar>
                </IonHeader> : ""
            }

            <IonContent className={'objectif content_form'}>
                <div className="objectifInter">
                    {
                        isEmpty(question) && (
                            <SkeletonObjectif/>
                        )
                    }
                    {
                        !isEmpty(question) && (
                            <>
                                <div className="objectifInter_title_content">
                                    {
                                        isModify === "/modifyInterObj" ? "" : <img src={logo} alt={"logo"}/>
                                    }
                                    <div className="objectifInter_title_box">
                                        <h1 className="objectifInter_title_vert title_form_fredoka">Mes Objectifs </h1>
                                        <h1 className="objectifInter_title_bleu title_form_fredoka">intermédiaires</h1>
                                    </div>

                                    <p className="objectifInter_subtitle">Choisis ton ou tes <b
                                        style={{color: "var(--lightGreenFlit)"}}>objectifs intermédiaires</b> et ta
                                        préparation sera adaptée.</p>

                                </div>

                                <div className="objectifInter_content">

                                    <ModifyInter/>


                                    {
                                        list.length >= 1 ? <ul>

                                            {

                                                list.map((item, index) => (
                                                    <li key={index}>
                                                        <div className="objectifInter_list_obj">
                                                            <p className="objectifInter_list_obj_number">{index + 1} .</p>
                                                            <p className="objectifInter_list_obj_text_distance">{item.distance}</p>
                                                            <p className="objectifInter_list_obj_text_date">{moment(item.date).format('ll')}</p>
                                                            <AiIcons.AiFillMinusCircle
                                                                className="objectifInter_list_obj_icon"
                                                                onClick={() => deleteTodo(item.listId, item.id)}/>
                                                        </div>

                                                    </li>
                                                ))
                                            }


                                        </ul> : ""
                                    }


                                    {
                                        show && (
                                            <>
                                                <div className="objectifInter_content_choose">
                                                    {
                                                        list.length >= 1 && (<div className={"objectifInter_empty_left"}></div>)
                                                    }


                                                    <IonSelect name="distance"
                                                               onIonChange={(e) => {
                                                                   setDistance(
                                                                       e.target.value
                                                                   )
                                                               }}
                                                               interface="action-sheet" placeholder="Distance"
                                                               className="objectifInter_select">

                                                        {
                                                            !isEmpty(question) && (question.Data.map(q => (
                                                                <div>
                                                                    {
                                                                        q.name === "Pas d'objectif" ? "" : <IonSelectOption
                                                                            value={q.id}>{q.name}</IonSelectOption>

                                                                    }

                                                                </div>
                                                            )))
                                                        }
                                                    </IonSelect>


                                                    <div className="objectifInter_time">
                                                        <ul className="objectifInter_time_list">
                                                            <li className="objectifInter_time_list_item">
                                                                <IonButton
                                                                    id="open-modal"
                                                                    onClick={() => setShowModal(true)}
                                                                    className="objectifInter_modal_button"
                                                                    placeholder="Date"
                                                                    mode={'ios'}

                                                                >

                                                                    <div className="objectifInter_time_date_text">
                                                                        <p>Date</p>
                                                                    </div>

                                                                </IonButton>
                                                                <IonModal mode={isPlatform("ios") ? "ios" : "md"}
                                                                          className="objectifInter_modal" isOpen={showModal}
                                                                          onDidDismiss={() => addTodo()}>
                                                                    <IonDatetime
                                                                        max="2061-12-12"
                                                                        min={moment().format("YYYY-MM-DD")}
                                                                        defaultValue={date}
                                                                        presentation="date"
                                                                        name="date"
                                                                        onIonChange={handleChooseDate}
                                                                    ></IonDatetime>
                                                                </IonModal>
                                                            </li>
                                                        </ul>
                                                    </div>


                                                    {
                                                        list.length >= 1 && (
                                                            <div className={"objectifInter_empty_right"}></div>)
                                                    }

                                                </div>
                                            </>
                                        )
                                    }
                                    <div className="objectifInter_add_content">
                                        {
                                            add ? <AiIcons.AiFillPlusCircle onClick={() => setShow(true)}
                                                                            className="objectifInter_content_add_icon"></AiIcons.AiFillPlusCircle> :
                                                <button onClick={() => setShow(!show)}
                                                        className="objectifInter_content_add">{show ? "Annuler" : "Ajouter un objectif"}</button>
                                        }
                                    </div>


                                </div>

                                <FooterForm text={
                                    isModify === "/modifyInterObj" ? "Enregistrer" : "Suivant"} spin={spinner}
                                            onClick={handleClick}/>
                            </>
                        )
                    }

                </div>
            </IonContent>
        </IonPage>
    );
};

export default ObjectifInter;