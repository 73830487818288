import React, {useEffect, useState} from "react";
import {IonSkeletonText} from "@ionic/react";
import "./skeletonTrainingWeek.css";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {ChangeLogin} from "../../../../redux/slices/currentUser/currentUserSlice";
import {emptyTraining, getTraining} from "../../../../redux/slices/trainingData/trainingDataSlice";
import {isEmpty} from "../../../../Utils/Utils";
import {emptyGoals} from "../../../../redux/slices/goals/goalSlice";
import {forEachGoal} from "../../../../API/Goal/GetGoal";
import {forEachTraining} from "../../../../API/Training/GetTraining";


const SkeletonTrainingWeek = () => {
    const user = useSelector((state) => state.currentUser);
    const training = useSelector(state => state.training);
    const dispatch =useDispatch()





    useEffect(() => {
        const interval = setInterval(() => {
           dispatch(forEachTraining(user))
        }, 2000);
        return () => clearInterval(interval);

    }, [user]);
    return (
        <>
            <div className="skeletonWeekPlan_title">
                <h3 className="skeletonWeekPlan_title_text">
                    <IonSkeletonText
                        animated
                        style={{
                            width: "80%",
                            margin: "auto",
                            height: "30px",
                        }}
                    ></IonSkeletonText>
                </h3>
                <h3 className="skeletonWeekPlan_title_text">
                    <IonSkeletonText
                        animated
                        style={{
                            width: "80%",
                            margin: "auto",
                            height: "30px",
                        }}
                    ></IonSkeletonText>
                </h3>

                <h3 className="skeletonWeekPlan_cycle">
                    <IonSkeletonText
                        animated
                        style={{
                            width: "80%",
                            margin: "auto",
                            height: "30px",
                        }}
                    ></IonSkeletonText>
                </h3>
            </div>

            <ul className="skeletonWeekPlan_infoList">
                <li className="skeletonWeekPlan_infoList_item">
                    <IonSkeletonText
                        animated
                        style={{
                            width: "80%",
                            height: "10px",
                        }}
                    ></IonSkeletonText>
                </li>
                <li className="skeletonWeekPlan_infoList_item">
                    <IonSkeletonText
                        animated
                        style={{
                            width: "80%",
                            height: "10px",
                        }}
                    ></IonSkeletonText>
                </li>
                <li className="skeletonWeekPlan_infoList_item">
                    <IonSkeletonText
                        animated
                        style={{
                            width: "80%",
                            height: "10px",
                        }}
                    ></IonSkeletonText>
                </li>
            </ul>

            <ul className="skeletonWeekPlan_list">
                <li>
                    <IonSkeletonText
                        animated
                        style={{
                            width: "90%",
                            height: "80px",
                            margin: "10px auto",
                        }}
                    ></IonSkeletonText>
                </li>
                <li>
                    <IonSkeletonText
                        animated
                        style={{
                            width: "90%",
                            height: "80px",
                            margin: "10px auto",
                        }}
                    ></IonSkeletonText>
                </li>
                <li>
                    <IonSkeletonText
                        animated
                        style={{
                            width: "90%",
                            height: "80px",
                            margin: "10px auto",
                        }}
                    ></IonSkeletonText>
                </li>
                <li>
                    <IonSkeletonText
                        animated
                        style={{
                            width: "90%",
                            height: "80px",
                            margin: "10px auto",
                        }}
                    ></IonSkeletonText>
                </li>
            </ul>
        </>
    );
};

export default SkeletonTrainingWeek;
