import {
    IonContent,
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonText,
    isPlatform,
    IonImg,
    IonAlert, useIonAlert,
} from "@ionic/react";
import React, {useEffect, useState} from "react";
import {getGarmin} from "../../../../redux/slices/strava/stravaSlice";
import {useSelector, useDispatch} from "react-redux";
import {InAppBrowser} from "@awesome-cordova-plugins/in-app-browser";
import axios from "axios";
import logo from "../../../../assets/FormulaireAssets/6.png"
import "./connect.css";
import {useHistory} from "react-router";
import GarminLogo from "../../../../assets/FormulaireAssets/garminLogo.png"
import StravaLogo from "../../../../assets/FormulaireAssets/stravaLogo.png"
import {Capacitor} from "@capacitor/core";


const Connect = () => {
    // eslint-disable-next-line no-unused-vars
    const user = useSelector((state) => state.currentUser);
    const [showAlert, setShowAlert] = useState(false);
    const history = useHistory()
    const [alreadyConnect, setAlreadyConnect] = useState(false)
    const [token, setToken] = useState();
    const [tokenSecret, setTokenSecret] = useState()
    const dispatch = useDispatch()
    const garmin = useSelector(state => state.garmin)
    const [presentAlert] = useIonAlert()
    const [openModal, setOpenModal] = useState(false)




    //Strava redirect
    const redirectURI_Strava = "http://staging.api.flit.run/stravaSuccess";
    const redirectURI_Strava_prod = "https://coach.flit.run/stravaSuccess";

    // Garmin redirect

    const redirectURIMobile_Garmin_staging = "http://staging.api.flit.run/garminSuccess";
    const redirectURIMobile_Garmin_prod = "https://coach.flit.run/garminSuccess";




    /*
        const redirectURILocal = "http://localhost:8100/stravaSuccess";
    */




    useEffect(() => {
        if (window.location.href.includes("error=access_denied")) {
            setShowAlert(true)
        }

        // axios.post(`${process.env.REACT_APP_STAGING_V2_GARMIN}/garmin/getTokens`, {
        //     debug: false,
        //     data: {},
        //     args: {}
        // }).then(res => {
        //     if (res.status === 200) {
        //         setToken(res.data.Data[0].token);
        //         setTokenSecret(res.data.Data[0].tokenSecret)
        //
        //
        //     }
        //
        // }).catch(err => console.log(err))


    }, [dispatch, setTokenSecret, setToken])


    const handleGarminDesktop = () => {
        dispatch(getGarmin({token: token, secretToken: tokenSecret}))

        setTimeout(() => {
            window.location.replace(
                `https://connect.garmin.com/oauthConfirm?oauth_token=${token}`
            );

        }, 1000)


    };


    const handleGarminMobile = () => {

        dispatch(getGarmin({token: token, secretToken: tokenSecret}))

        const browser = InAppBrowser.create(
            `https://connect.garmin.com/oauthConfirm?oauth_token=${token}`, "_blank",
            "location=yes"
        );
        browser.on("loadstart").subscribe((event) => {


            if (event.url.indexOf(redirectURIMobile_Garmin_prod) === 0) {
                const codeData = event.url.split("oauth_verifier=");


                axios
                    .post(`${process.env.REACT_APP_STAGING_V2_GARMIN}/garmin/initUser`, {
                        debug: false,
                        data: {
                            userId: user.id,
                            verify: codeData,
                            oauth_token: token,
                            oauth_secret: tokenSecret
                        },
                        args: {}
                    })
                    .then((res) => {

                        if (res.status === 204) {
                            browser.close();
                            history.push("/noviceLevel")
                        }
                        if (res.status === 409) {
                            browser.close()
                            setAlreadyConnect(true)
                        }
                        if (res.status === 200 || res.status === 201) {
                            browser.close();
                            history.push("/disponibilityWeek")
                        }


                    })
                    .catch((err) => console.error(err));

            }


            if (event.url.includes("error=access_denied")) {
                setShowAlert(true);
                browser.close();
            }
        });


    }


    const handleStravaDesktop = async () => {
        window.location.replace(
            `https://www.strava.com/oauth/authorize?client_id=${process.env.REACT_APP_STRAVA_CLIENT_ID}&response_type=code&redirect_uri=${redirectURI_Strava}&approval_prompt=force&scope=activity:read_all`
        );
    };
    const handleStravaMobile = async (resolve, reject) => {
        const browser = InAppBrowser.create(
            `https://www.strava.com/oauth/authorize?client_id=${process.env.REACT_APP_STRAVA_CLIENT_ID}&response_type=code&redirect_uri=${redirectURI_Strava}&approval_prompt=force&scope=activity:read_all`, "_blank",
            "location=yes"
        );


        browser.on("loadstart").subscribe((event) => {

            if (event.url.indexOf(redirectURI_Strava) === 0) {
                const codeData = event.url.split("code=").pop().split("&scope")[0];

                axios
                    .post(
                        `${process.env.REACT_APP_STAGING_V2_STRAVA}/strava/getuserdata`,
                        {
                            debug: false,
                            data: {
                                userId: user.id,
                                code: codeData,
                            },
                            args: {},
                        },
                        {
                            headers: {
                                Authorization: user.token,
                            },
                        }
                    )
                    .then((res) => {
                        if (res.status === 204) {
                            browser.close();
                            history.push("/noviceLevel")
                        }
                        if (res.status === 409) {
                            browser.close()
                            setAlreadyConnect(true)
                        }
                        if (res.status === 200 || res.status === 201) {
                            browser.close();
                            history.push("/disponibilityWeek")
                        }
                    })
                    .catch((err) => {

                    });


            }
            if (event.url.includes("error=access_denied")) {
                setShowAlert(true);
                browser.close();
            }
        });


    };

    return (
        <IonPage>

            <IonContent fullscreen className={'objectif content_form'}>
                <div className="connect div_principal">
                    <div className="connect_global_title">
                        <IonImg src={logo} className="connect_logo"/>
                        <div className={"connect_title_box"}>
                            <h1 className="connect_title_vert title_form_fredoka">Synchroniser</h1>
                            <h1 className="connect_title_blue title_form_fredoka">mes applis</h1>
                        </div>

                        <div className="connect_textContent">
                            <IonText>
                                <p>
                                    Connecte une <b style={{color: 'var(--lightGreenFlit)'}}>appli de running</b> pour
                                    que tes séances passées et à venir soient
                                    analysées.
                                </p>
                            </IonText>

                        </div>
                        {
                            alreadyConnect && (
                                <p style={{color: "red", textAlign: "center"}}>Oups ! Ton profil est déjà lié à un de nos
                                    comptes !</p>
                            )
                        }


                        <ul className={'connect_button_list'}>
                            {/*<li onClick={*/}
                            {/*    isPlatform("desktop") || isPlatform("mobileweb") ? handleGarminDesktop : handleGarminMobile*/}

                            {/*} className={'connect_button_list_item'}>*/}
                            {/*    <img src={GarminLogo} alt={"garmin logo"} className={'connect_button_list_img'}/>*/}
                            {/*    <p className={'connect_button_list_text'}>Garmin</p>*/}
                            {/*</li>*/}
                            <li className={'connect_button_list_item'} onClick={
                                isPlatform("desktop") || isPlatform("mobileweb") ? handleStravaDesktop : handleStravaMobile
                            }>
                                <img src={StravaLogo} alt={"strava logo"} className={'connect_button_list_img'}/>
                                <p className={'connect_button_list_text'}>Strava</p>
                            </li>
                            <li onClick={() => setOpenModal(true)}>
                                <p className={'connect_button_list_text_noAccount'}>Je n’ai pas d’appli
                                    d’entraînement</p>
                            </li>

                        </ul>

                        <IonAlert
                            mode={Capacitor.getPlatform() === 'ios' ? "ios" : "md"}
                            isOpen={openModal}
                            cssClass={'connect_modal_noAccount'}
                            onDidDismiss={() => setOpenModal(false)}
                            message="Sélectionne l’option Strava puis “S’inscrire” (en haut à droite) : tu peux créer gratuitement ton compte Strava en 30 secondes et l’associer avec Flit Run dans la foulée !"
                            buttons={['OK']}
                        />


                        <IonAlert
                            mode={Capacitor.getPlatform() === 'ios' ? "ios" : "md"}
                            isOpen={showAlert}
                            onDidDismiss={() => {
                                setShowAlert(false);
                            }}
                            header="Oops !"
                            subHeader="Une erreur est survenue"
                            message="Réessaie plus tard !"
                            buttons={[{
                                text: "Ok",
                                handler: (() => history.push('/connect'))
                            }]}
                        />
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Connect;
