import {createSlice} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

export const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState: [],
    reducers: {
        currentUser: (state, action) => ({...state, ...action.payload}),
    },
});

export const isLogginSlice = createSlice({
    name: 'isLogin',
    initialState: {
        isLoggin: false,
        isRegister: false
    },
    reducers: {
        ChangeLogin: (state, action) => ({...state, ...action.payload}),
    },
});
export const logOutSlice = createSlice({
    name: 'logOut',
    initialState: [],
    reducers: {
        logOut: (state, action) => {

            return state
        },
    },
});

export const noviceSlice = createSlice({
    name: 'novice',
    initialState: {
        typeId: null,
        number: null,
        time: null,
        distance: null
    },
    reducers: {
        getNovice: (state, action) => ({...state, ...action.payload}),
    },
});

// Slice for VIP Status

export const VIPSlice = createSlice({
    name: "VIP",
    initialState:
        {
            isVip: true,
            isTrialPeriod: true,
        }
    ,
    reducers: {
        GetisVip: (state, action) => ({...state, ...action.payload})
    }
})

export const {currentUser} = currentUserSlice.actions;
export const {logOut} = logOutSlice.actions;
export const {getNovice} = noviceSlice.actions;
export const {ChangeLogin} = isLogginSlice.actions;
export const {GetisVip} = VIPSlice.actions
