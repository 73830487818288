import React from "react";
import {
  IonLabel,
  IonSkeletonText,
  IonItem,
  IonList,
  IonListHeader,
  IonAvatar,
  IonThumbnail,
  IonContent,
} from "@ionic/react";

const SkeletonObjectif = () => {
  return (
    <IonContent>
      <>
        <div className="typeOfRun_logo">
          <IonSkeletonText
            animated
            style={{ width: "100%", margin: "30px auto", height: "200px" }}
          />
        </div>

        <div className="typeOfRun_textContent">
          <p>
            <IonSkeletonText
              animated
              style={{ width: "100%" }}
            ></IonSkeletonText>
          </p>

          <p>
            <IonSkeletonText
              animated
              style={{ width: "100%" }}
            ></IonSkeletonText>
          </p>
          <p>
            <IonSkeletonText
              animated
              style={{ width: "100%" }}
            ></IonSkeletonText>
          </p>
          <p>
            <IonSkeletonText
              animated
              style={{ width: "100%" }}
            ></IonSkeletonText>
          </p>
          <p>
            <IonSkeletonText
              animated
              style={{ width: "100%" }}
            ></IonSkeletonText>
          </p>
        </div>

        <ul className="typeOfRun_list">
          <li>
            <IonSkeletonText
              animated
              style={{ height: "50px" }}
            ></IonSkeletonText>
          </li>
          <li>
            <IonSkeletonText
              animated
              style={{ height: "50px" }}
            ></IonSkeletonText>
          </li>
          <li>
            <IonSkeletonText
              animated
              style={{ height: "50px" }}
            ></IonSkeletonText>
          </li>
          <li>
            <IonSkeletonText
              animated
              style={{ height: "50px" }}
            ></IonSkeletonText>
          </li>
          <li>
            <IonSkeletonText
              animated
              style={{ height: "50px" }}
            ></IonSkeletonText>
          </li>
        </ul>
      </>
    </IonContent>
  );
};

export default SkeletonObjectif;
