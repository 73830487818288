import React, {useEffect, useState} from "react";
import {useIonAlert, IonAlert, IonNav} from "@ionic/react";
import {useHistory, Link} from "react-router-dom";
import moment from "moment";
import trente from "../../../../assets/iconSeance/3030.png"
import seuil from "../../../../assets/iconSeance/seuil.png"
import sortieLongue from "../../../../assets/iconSeance/sortie_longue.png"
import entrainementNP from "../../../../assets/iconSeance/entrainement_non_planifie.png"
import ligneDroite from "../../../../assets/iconSeance/lignes droites.png"
import progressif from "../../../../assets/iconSeance/progressif.png"
import enduranceFondamental from "../../../../assets/iconSeance/endurance_fondamentale.png"
import allureSoutenue from "../../../../assets/iconSeance/footing_allure_soutenue.png"
import fractionneBI from "../../../../assets/iconSeance/fractionne_basse_intensit‚.png"
import fractionneCourt from "../../../../assets/iconSeance/fractionne_court.png"
import fractionneLong from "../../../../assets/iconSeance/fractionne_long.png"
import tempo from "../../../../assets/iconSeance/tempo.png"
import VMAIcon from "../../../../assets/iconSeance/VMA.png"
import cote from "../../../../assets/iconSeance/cote.png"
import footingAllure from "../../../../assets/iconSeance/footing_allure_soutenue.png"
import chaussure from "../../../../assets/menu/semaine.png"


import "./trainingWeekPlan.css";
import {IonIcon, IonText} from "@ionic/react";
import {useDispatch, useSelector} from "react-redux";
import {
    decrement,
    defaultValue, getShowModal,
} from "../../../../redux/slices/trainingData/trainingDataSlice";
import SkeletonTrainingWeek from "../SkeletonTrainingWeek/SkeletonTrainingWeek";
import FabButton from "../../../../pages/FabButton";

import {increment} from "../../../../redux/slices/trainingData/trainingDataSlice";
import * as FiIcons from "react-icons/fi"

import {isEmpty} from "../../../../Utils/Utils";
import Star from "./content/Star";
import {
    chevronBack, chevronBackOutline,

    chevronForwardCircle, chevronForwardOutline,
    informationCircleOutline
} from "ionicons/icons";
import {ChangeLogin, logOut} from "../../../../redux/slices/currentUser/currentUserSlice";
import TrainingDayPlan from "../trainingDayPlan/content/TrainingDayPlan";
import {buyPackage} from "../../Profil/shops";
import ModalNotVip from "../../../Reusable/ModalNotVip";

const TrainingWeekPlan = ({
                              user,
                              training,
                              setError,
                              error,
                              mounted,
                              setMounted,
                              count

                          }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [getWeek, setGetWeek] = useState("")
    const showModal = useSelector(state => state.showModal)
    const [presentAlert] = useIonAlert();
    const isVip = useSelector(state => state.isVIP)
    const [showAlert, setShowAlert] = useState(false)
    const [mountedtest, setMountedTest] = useState(false)


    // const getWeek = training.temporal_id.split("");
    const today = moment().format("dddd");


    useEffect(() => {
        setTimeout(() => {
            setMountedTest(true)
        }, 500)
    }, [])
    const handleNewPlan = () => {
        const sundayWeekEarly = moment()
            .year(
                parseInt(training[0].Plan.TemporalPlanId.toString().split("")[0] + "" + training[0].Plan.TemporalPlanId.toString().split("")[1] + "" + training[0].Plan.TemporalPlanId.toString().split("")[2] + "" + training[0].Plan.TemporalPlanId.toString().split("")[3])
            )
            .isoWeek(parseInt(training[0].Plan.TemporalPlanId.toString().split("")[4] + "" + training[0].Plan.TemporalPlanId.toString().split("")[5]))
            .endOf("week")
            .hour(0)
            .minute(0)
            .second(0);
        const sundayWeekEnd = moment()
            .year(
                parseInt(training[0].Plan.TemporalPlanId.toString().split("")[0] + "" + training[0].Plan.TemporalPlanId.toString().split("")[1] + "" + training[0].Plan.TemporalPlanId.toString().split("")[2] + "" + training[0].Plan.TemporalPlanId.toString().split("")[3])
            )
            .isoWeek(parseInt(training[0].Plan.TemporalPlanId.toString().split("")[4] + "" + training[0].Plan.TemporalPlanId.toString().split("")[5]))
            .endOf("week")
            .hour(23)
            .minute(59)
            .second(59);


        if (moment() >= sundayWeekEarly && moment() < sundayWeekEnd) {
            presentAlert({
                header: 'Attention, si tu passe à la semaine suivante tu ne pourras plus modifier la semaine actuelle.',
                buttons: [
                    {
                        text: "Annuler",
                        role: "cancel",

                    },
                    {
                        text: "Continuer",
                        role: "confirm",
                        handler: () => {
                            history.push("/weekbilanallure");
                            ;
                        },
                    },
                ]


            })
        } else {
            history.push("/weekbilanallure");
        }
        /*  si res.status =200 */

    };


    const iconSeance = (data) => {

        if (data === "30/30") {
            return trente
        }
        if (data === "Seuil") {
            return seuil
        }
        if (data === "Entraînement non planifié" || data === "Non planifié") {
            return entrainementNP
        }
        if (data === "Endurance fondamentale") {
            return enduranceFondamental
        }
        if (data === "Côtes") {
            return cote
        }
        if (data === "VMA") {
            return VMAIcon
        }
        if (data === "Tempo") {
            return tempo
        }
        if (data === "Sortie longue") {
            return sortieLongue
        }
        if (data === "Basse intensité") {
            return fractionneBI
        }
        if (data === "Fractionné long") {
            return fractionneLong
        }
        if (data === "Allure soutenue") {
            return allureSoutenue
        }
        if (data === "Progressif") {
            return progressif
        }
        if (data === "Fractionné court") {
            return fractionneCourt
        }
        if (data === "Lignes droites") {
            return ligneDroite
        } else {
            return chaussure
        }
    }


    return (
        <div className="trainingWeekPlan">
            <>
                {
                    mountedtest && (


                        <>
                            {
                                !isEmpty(training) && (


                                    <>
                                        <>
                                            <div className="trainingWeekPlan_title_history">


                                                <div className="historique_increment">

                                                    <button
                                                        className={training.length - 1 === count || training.length === 1 ? "historique_decrement_none" : "historique_decrement"}
                                                        onClick={async () => {
                                                            dispatch(increment())
                                                            await setGetWeek(training[count].Plan.TemporalPlanId.toString().split(""))
                                                        }}
                                                        disabled={training.length - 1 === count || training.length === 1 ? "disabled" : ""}>
                                                        <IonIcon icon={chevronBackOutline}
                                                                 className={training.length - 1 === count || training.length === 1 ? "historique_decrement_none" : ""}
                                                        />

                                                    </button>

                                                    <button onClick={async () => {
                                                        dispatch(defaultValue());
                                                        await setGetWeek(training[count].Plan.TemporalPlanId.toString().split(""))
                                                    }}
                                                            className={count >= 1 ? "historique_double_none" : "historique_double_none"}
                                                            disabled={count >= 1 ? "disabled" : "disabled"}>
                                                        <IonIcon icon={chevronForwardOutline}/>
                                                    </button>

                                                </div>

                                                <div className="trainingWeekPlan_title">
                                                    <IonText>
                                                        <h3 className="trainingWeekPlan_title_text">
                                                            {
                                                                !isEmpty(training[count]) && (
                                                                    <>
                                                                        {moment()
                                                                            .isoWeek(parseInt(training[count].Plan.TemporalPlanId.toString().split("")[4] + "" + training[count].Plan.TemporalPlanId.toString().split("")[5]))
                                                                            .startOf("week").format("DD")} au{" "}
                                                                        {moment()
                                                                            .isoWeek(parseInt(training[count].Plan.TemporalPlanId.toString().split("")[4] + "" + training[count].Plan.TemporalPlanId.toString().split("")[5]))
                                                                            .endOf("week").format("DD MMMM")}

                                                                    </>
                                                                )
                                                            }
                                                        </h3>
                                                    </IonText>


                                                </div>
                                                <div className="historique_decrement_content">

                                                    <ModalNotVip showAlert={showAlert} setShowAlert={setShowAlert}/>


                                                    {
                                                        showModal.showModal === true && count === 0 ?
                                                            <IonIcon
                                                                className={isVip.isVip === false ? 'weekBilanButtonShow_notVip' : "weekBilanButtonShow"}
                                                                onClick={isVip.isVip === false ? () => setShowAlert(true) : handleNewPlan}
                                                                icon={chevronForwardCircle}/> :

                                                            <button onClick={async () => {
                                                                if (count !== 0) {
                                                                    dispatch(decrement());
                                                                    await setGetWeek(training[count].Plan.TemporalPlanId.toString().split(""))


                                                                }
                                                                if (count === 0) {

                                                                    presentAlert({
                                                                        header: 'Tu pourras générer ta prochaine semaine d’entrainement à partir de dimanche.',
                                                                        buttons: ["OK"],
                                                                    })
                                                                }

                                                            }}

                                                                    className={count === 0 || training.length === 1 ? "historique_decrement_none" : "historique_decrement"}
                                                            >
                                                                <FiIcons.FiChevronRight

                                                                    className={count === 0 || training.length === 1 ? "historique_decrement_none" : "historique_decrement"}

                                                                />
                                                            </button>


                                                    }


                                                    <button onClick={async () => {
                                                        dispatch(defaultValue());
                                                        await setGetWeek(training[count].Plan.TemporalPlanId.toString().split(""))
                                                    }}
                                                            className={count >= 1 ? "historique_double" : "historique_double_none"}
                                                            disabled={count >= 1 ? "" : "disabled"}>
                                                        <FiIcons.FiChevronsRight/>
                                                    </button>


                                                </div>


                                            </div>


                                            <div className="trainingWeekPlan_submesocycle">
                                                <IonText>
                                                    {
                                                        !isEmpty(training[count].Plan.CycleInformation) && (
                                                            <h3 className="trainingWeekPlan_cycle">
                                                                {training[count].Plan.CycleInformation.current_submesocycle}
                                                            </h3>
                                                        )
                                                    }
                                                </IonText>
                                                {
                                                    !isEmpty(training[count].Plan.CycleInformation) && (
                                                        <Link

                                                            to={`/pedagogieDetails/${training[count].Plan.CycleInformation.current_submesocycle}`}
                                                            className="trainingWeekPlan_link"
                                                        >
                                                            {
                                                                !isEmpty(training[count].Plan.CycleInformation.current_submesocycle) && (

                                                                    <IonIcon className="trainingWeek_info_icon"
                                                                             icon={informationCircleOutline}></IonIcon>
                                                                )
                                                            }
                                                        </Link>
                                                    )
                                                }


                                            </div>


                                            <ul className="trainingWeekPlan_list">


                                                <li>
                                                    <div className="trainingWeekPlan_bar"></div>
                                                </li>
                                                <div className="trainingWeekPlan_allContent">

                                                    {/* training.data.map */}
                                                    {training[count].Plan.Plan.map((t, index) => (
                                                        <div key={index}>
                                                            <li>
                                                                {t.session_information === "" || t.session_information === null ? (
                                                                    <div className="trainingWeekPlanBox_day_repos">
                                                                        <div className="trainingPlanBox_day_repos_content">
                                                                            <div
                                                                                className="trainingWeekPlanBox_day_repos_title_content">
                                                                                <div
                                                                                    className={

                                                                                        moment().isoWeek() > parseInt(moment().isoWeek(training[0].Plan.TemporalPlanId.toString().split("")[4] + training[0].Plan.TemporalPlanId.toString().split("")[5]).isoWeek().toString()) ? "" :
                                                                                            today === t.day.toLowerCase() && count === 0
                                                                                                ? "trainingWeek_label_point_training"
                                                                                                : "bar_none"


                                                                                    }
                                                                                ></div>
                                                                                <p
                                                                                    className={
                                                                                        today === t.day.toLowerCase() && count === 0
                                                                                            ? "trainingWeekPlanBox_day_repos_day actived_day"
                                                                                            : "trainingWeekPlanBox_day_repos_day"
                                                                                    }
                                                                                >
                                                                                    {t.day}
                                                                                </p>
                                                                            </div>

                                                                            <div className="traininWeekPlan_text_content">
                                                                                <p className="trainingWeekPlanBox_day_repos_data">
                                                                                    Repos
                                                                                </p>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className="trainingWeekPlanBox" key={index}>
                                                                        <Link
                                                                            to={`${t.day}/trainingDay`}
                                                                            className="trainingWeekPlan_link"
                                                                        >
                                                                            {(t.session_information.unexpected === false && t.session_information.success === false) ||
                                                                            (t.session_information.unexpected === undefined &&
                                                                                t.session_information.success === undefined) ||
                                                                            (t.session_information.unexpected === false &&
                                                                                t.session_information.success === undefined) || training[count].Plan.Plan.map(m => m.session_information.session_basic_data) === undefined ? (

                                                                                <> {
                                                                                    moment().year(
                                                                                        parseInt(training[count].Plan.TemporalPlanId.toString().split("")[0] + "" + training[count].Plan.TemporalPlanId.toString().split("")[1] + "" + training[count].Plan.TemporalPlanId.toString().split("")[2] + "" + training[count].Plan.TemporalPlanId.toString().split("")[3]))

                                                                                        .isoWeek(parseInt(training[count].Plan.TemporalPlanId.toString().split("")[4] + "" + training[count].Plan.TemporalPlanId.toString().split("")[5])).isoWeekday(t.day) <
                                                                                    moment().year(
                                                                                        parseInt(training[0].Plan.TemporalPlanId.toString().split("")[0] + "" + training[0].Plan.TemporalPlanId.toString().split("")[1] + "" + training[0].Plan.TemporalPlanId.toString().split("")[2] + "" + training[0].Plan.TemporalPlanId.toString().split("")[3]))

                                                                                        .isoWeek(parseInt(training[0].Plan.TemporalPlanId.toString().split("")[4] + "" + training[0].Plan.TemporalPlanId.toString().split("")[5])) ?
                                                                                        <div
                                                                                            className="trainingWeekPlanBox_boxIcon">
                                                                                            <Star
                                                                                                score={"0"}/>

                                                                                        </div> : ""
                                                                                }</>

                                                                            ) : (


                                                                                <>


                                                                                    <div
                                                                                        className="trainingWeekPlanBox_boxIcon">
                                                                                        <Star
                                                                                            score={t.session_information.session_basic_data.score.split("%")[0]}/>

                                                                                    </div>


                                                                                </>
                                                                            )}

                                                                            <div>
                                                                                <div
                                                                                    className="trainingWeekPlanBox_day_repos_title_content">
                                                                                    <div
                                                                                        className={
                                                                                            today === t.day.toLowerCase() && count === 0
                                                                                                ? "trainingWeek_label_point_training"
                                                                                                : "bar_none"
                                                                                        }
                                                                                    ></div>

                                                                                    <p
                                                                                        className={
                                                                                            today === t.day.toLowerCase() && count === 0
                                                                                                ? "trainingWeekPlanBox_day actived_day"
                                                                                                : "trainingWeekPlanBox_day"
                                                                                        }
                                                                                    >
                                                                                        {t.day}
                                                                                    </p>
                                                                                </div>

                                                                                <div className="trainingWeekPlanBox_session">
                                                                                    <div
                                                                                        key={t.label}
                                                                                        className="trainingWeekPlanBox_session_content"
                                                                                    >
                                                                                        <div
                                                                                            className="trainingWeekPlanBox_session_content_boxIcon">
                                                                                            <img
                                                                                                src={iconSeance(t.session_information.title.split("-")[0].trim())}
                                                                                                className="trainingWeekPlanBox_session_content_icon"
                                                                                                alt={"logo seance"}/>
                                                                                        </div>
                                                                                        {
                                                                                            t.session_information.unexpected === true || t.session_information.title.split(" - ")[1] === "?" ?
                                                                                                <>
                                                                                                    <div
                                                                                                        className="trainingWeekPlanBox_session_content_box_unexpected">
                                                                                                        {
                                                                                                            t.session_information.title.split(" - ")[1] === "?" ?
                                                                                                                <p>{t.session_information.title.split(" - ")[0]}</p> :
                                                                                                                <p>{t.session_information.title}</p>
                                                                                                        }

                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="trainingWeekPlanBox_session_content_boxTime">
                                                                                                        <p>
                                                                                                            -
                                                                                                        </p>

                                                                                                    </div>
                                                                                                </>

                                                                                                :
                                                                                                <>
                                                                                                    <div
                                                                                                        className="trainingWeekPlanBox_session_content_box">
                                                                                                        <p>{t.session_information.title.split("-")[0]}</p>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="trainingWeekPlanBox_session_content_boxTime">
                                                                                                        <p>{t.session_information.title.split("-")[1].split(':').length === 2 ? t.session_information.title.split("-")[1].split(':')[0] + " min" : t.session_information.title.split("-")[1].split(':')[0] + "h" + t.session_information.title.split("-")[1].split(':')[1]}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </>
                                                                                        }

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                )}{" "}
                                                            </li>
                                                        </div>
                                                    ))}
                                                </div>
                                            </ul>
                                        </>
                                    </>
                                )
                            }
                        </>
                    )
                }
            </>
        </div>
    );
};

export default TrainingWeekPlan;
