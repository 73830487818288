import {
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonRouterOutlet,
    IonLabel
} from "@ionic/react";
import "./tabBar.css";

import {Route} from "react-router";
import Tab1 from "./Tab1";
import Tab4 from "./Tab4";
import Tab3 from "./Tab3";
import Tab2 from "./Tab2";
import Settings from "../components/App/Profil/Settings/Setting";
import Paiement from "../components/App/Profil/Settings/Paiements/Paiement";
import {isPlatform} from "@ionic/react";
import {useEffect, useState} from "react";
import Contact from "../components/App/Profil/Contact/Contact";
import WeekBilanAllure from "../components/App/NewPlan/WeekBilan/WeekBilanAllure";
import NewGoal from "../components/App/Profil/NewGoal/NewGoal";
import NewGoalDisponibility from "../components/App/Profil/NewGoal/NewGoalDisponibility";
import WeekBilanDisponibility from "../components/App/NewPlan/WeekBilan/WeekBilanDisponibility";
import PedagogieDetails from "../components/App/Pedagogie/content/content/PedagogieDetails";
import WeekBilanVolume from "../components/App/NewPlan/WeekBilan/WeekBilanVolume";
import Day from "../components/App/Home/trainingDayPlan/Day";
import FormSuccess from "../components/Formulaire/FormContent/FormSuccess/FormSuccess";
import semaine from "../assets/menu/chaussure_bleu.png"
import semaineVerte from "../assets/menu/chaussure_vert.png"
import pedagogie from "../assets/menu/conseil_bleu.png"
import pedagogieVert from "../assets/menu/conseil_vert.png"

import profil from "../assets/menu/profil_bleu.png"
import profilVert from "../assets/menu/profil_vert.png"
import stats from "../assets/menu/analyse_bleu.png"
import statsVerte from "../assets/menu/analyse_vert.png"
import {Capacitor} from "@capacitor/core";
import {useDispatch, useSelector} from "react-redux";
import Objectif from "../components/Formulaire/FormContent/Objectif/Objectif";
import ObjectifInter from "../components/Formulaire/FormContent/Objectif/ObjectifInter/ObjectifInter";

const TabBar = () => {
    const [tabPlatform, setTabPlatform] = useState("");

    const [selected, setSelected] = useState({
        semaine: true,
        stats: false,
        conseil: false,
        profil: false
    })

    const dispatch = useDispatch()


    useEffect(() => {
        if (isPlatform("android")) {
            setTabPlatform("tabBar_mobile");
        }
        if (isPlatform("ios")) {
            setTabPlatform("tabBar_mobile");
        }
        if (isPlatform("desktop")) {
            setTabPlatform("tabBar_desktop");
        }
        if (isPlatform("capacitor")) {
            setTabPlatform("tabBar_mobile");
        }
        if (isPlatform("mobileweb")) {
            setTabPlatform("tabBar_mobile");
        }

    }, [])
    return (
        <>
            <IonTabs className="tabs_main">
                <IonRouterOutlet
                    animated={Capacitor.getPlatform() === 'ios' ? false : true}
                >
                    <Route exact path="/tab1">
                        <Tab1/>
                    </Route>
                    <Route path="/tab4">
                        <Tab4/>
                    </Route>
                    <Route path="/tab3">
                        <Tab3/>
                    </Route>
                    <Route path="/tab2">
                        <Tab2/>
                    </Route>


                    <Route path="/settings" exact component={Settings}/>
                    <Route path="/subscribe" exact component={Paiement}/>
                    <Route path="/:day/trainingDay" exact component={Day}/>
                    <Route path="/contact" exact component={Contact}/>
                    <Route path="/weekbilanallure" exact component={WeekBilanAllure}/>
                    <Route path="/weekbilanvolume" exact component={WeekBilanVolume}/>
                    <Route path="/newGoal" exact component={NewGoal}/>
                    <Route path="/formSuccess" exact component={FormSuccess}/>
                    <Route path="/modifyMainObj" exact component={Objectif}/>
                    <Route path="/modifyInterObj" exact component={ObjectifInter}/>
                    <Route
                        path="/newGoalDisponibility"
                        exact
                        component={NewGoalDisponibility}
                    />
                    <Route
                        path="/weekbilandisponibility"
                        exact
                        component={WeekBilanDisponibility}
                    />
                    <Route path="/pedagogieDetails/:libelle" component={PedagogieDetails}/>


                </IonRouterOutlet>
                <IonTabBar
                    className={tabPlatform}
                    slot={isPlatform("desktop") ? "top" : "bottom"}
                >
                    <IonTabButton onClick={() => setSelected({
                        semaine: true,
                        stats: false,
                        conseil: false,
                        profil: false
                    })} className="tabBar_button" tab="/tab1" href="/tab1">

                        <img className="icon_tabBar" src={selected.semaine ? semaineVerte : semaine} alt={"semaine"}/>
                        <IonLabel
                            className={selected.semaine ? "tabBar_label_green" : "tabBar_label"}>Programme</IonLabel>
                    </IonTabButton>


                    {/*<IonTabButton onClick={() => setSelected({*/}
                    {/*    semaine: false,*/}
                    {/*    stats: true,*/}
                    {/*    conseil: false,*/}
                    {/*    profil: false*/}
                    {/*})} className="tabBar_button" tab="/tab2" href="/tab2">*/}
                    {/*    <img src={selected.stats ? statsVerte : stats} alt={'stat'} className="icon_tabBar"/>*/}
                    {/*    <IonLabel className={selected.stats ? "tabBar_label_green" : "tabBar_label"}>Analyses</IonLabel>*/}
                    {/*</IonTabButton>*/}

                    <IonTabButton onClick={() => setSelected({
                        semaine: false,
                        stats: false,
                        conseil: true,
                        profil: false
                    })} className="tabBar_button" tab="/tab3" href="/tab3">
                        <img className="icon_tabBar" src={selected.conseil ? pedagogieVert : pedagogie}
                             alt={"pedagogie"}/>
                        <IonLabel
                            className={selected.conseil ? "tabBar_label_green" : "tabBar_label"}>Conseil</IonLabel>
                    </IonTabButton>
                    <IonTabButton onClick={() => setSelected({
                        semaine: false,
                        stats: false,
                        conseil: false,
                        profil: true
                    })} className="tabBar_button" tab="/tab4" href="/tab4">
                        <img className="icon_tabBar" src={selected.profil ? profilVert : profil} alt={"profil"}/>
                        <IonLabel className={selected.profil ? "tabBar_label_green" : "tabBar_label"}>Profil</IonLabel>
                    </IonTabButton>
                </IonTabBar>

            </IonTabs>
        </>
    );
};

export default TabBar;
