import {IonIcon, IonAlert, isPlatform} from "@ionic/react";
import {chevronForward, chevronDown, exitOutline, mailOpenOutline} from "ionicons/icons";
import {useDispatch, useSelector} from "react-redux";
import "./profil.css";
import {useHistory, Link} from "react-router-dom";
import {
    ChangeLogin, logOut
} from "../../../redux/slices/currentUser/currentUserSlice";
import {calendar, closeOutline} from "ionicons/icons";
import moment from "moment";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {emptyGoals, getGoal} from "../../../redux/slices/goals/goalSlice";
import {forEachGoal} from "../../../API/Goal/GetGoal";
import * as AiIcons from "react-icons/ai"
import logoProfil from "../../../assets/FormulaireAssets/APPLI - Visuels.png"
import {updateDisponibility} from "../../../redux/slices/disponibility/disponibility";
import {isEmpty} from "../../../Utils/Utils";
import ProfilModal from "./modal/ProfilModal";
import ObjMain from "../../../assets/profil/4.png"
import ObjInter from "../../../assets/profil/5.png"
import Nbseance from "../../../assets/profil/6.png"
import connect from "../../../assets/profil/7.png"
import settings from "../../../assets/profil/8.png"
import euro from "../../../assets/profil/APP - Étoiles réussite.png"
import AddObj from "./ProfilAddObj/AddObj";
import SkeletonProfil from "./Skeleton/SkeletonProfil";
import Shop from "./Shop";
import {AppLauncher} from "@capacitor/app-launcher";
import {GetDisponibility} from "../../../API/Disponibility/GetDisponibility";
import {Capacitor} from "@capacitor/core";
import {App} from "@capacitor/app";
import {GetisVip} from "../../../redux/slices/currentUser/currentUserSlice";
import ModalNotVip from "../../Reusable/ModalNotVip";
import {store} from "../../../redux/redux"
import {IsVipOrNot} from "../../../API/User/VipOrNot";
import {EmailComposer} from "@awesome-cordova-plugins/email-composer";


/*
import UpdateProfil from "./UpdateProfil/UpdateProfil";
*/

const Profil = () => {

    const history = useHistory();
    const [showAlert, setShowAlert] = useState(false);
    const currentUser = useSelector((state) => state.currentUser);
    const disponibility = useSelector(state => state.disponibility)
    const dispatch = useDispatch();
    const [showMain, setShowMain] = useState(false);
    const [showInter, setShowInter] = useState(false);
    const goal = useSelector(state => state.goal);
    const isVip = useSelector(state => state.isVIP);
    const [showModal, setShowModal] = useState(false);
    const [test, setTest] = useState();
    const [showDcAlert, setShowDcAlert] = useState(false);
    const training = useSelector(state => state.training)


    const handleDeconnect = () => {
        setShowDcAlert(true);
    };


    useEffect(() => {

        const data = async () => {
            await dispatch(GetDisponibility(currentUser));
            await dispatch(IsVipOrNot(currentUser))
        }
        dispatch(emptyGoals())
        dispatch(forEachGoal(currentUser))
        setTimeout(() => {
            data();
        }, 500)

    }, [currentUser])


    let email = {
        to: `support@flit-sport.fr`,
        subject: 'Support Flit',
        body: 'Ecris ta requête',
        isHtml: true
    }


    return (

        <div className="profil">
            {
                isEmpty(goal[0]) && isEmpty(disponibility) && (
                    <SkeletonProfil handleDeconnect={handleDeconnect}/>
                )
            }
            {
                !isEmpty(goal[0]) && !isEmpty(disponibility) && (
                    <>
                        <div className={"profil_header"}>
                            <div className={"profil_title_icon"}>
                                <div></div>
                                <IonIcon onClick={handleDeconnect} className={"profil_header_icon"} icon={exitOutline}/>

                            </div>
                            <div className={"profil_header_logo_name"}>
                                <img className={"profil_logo"} src={logoProfil} alt={"logoProfil"}/>
                                <h3>{currentUser.lastName}</h3>
                            </div>
                        </div>

                        <div className={"profil_content"}>

                            <ul className={"profil_content_list"}>
                                <div
                                    onClick={isVip.isVip === true ? () => history.push("/modifyMainObj") : () => setShowAlert(true)}>
                                    <li className={"profil_content_list_item"}>
                                        <div className={"profil_content_box"}>
                                            <img src={ObjMain} alt={"objectif"} className={"profil_content_icon"}/>
                                            <div className="profil_content_text_box">
                                                <p className={'profil_content_text'}>Objectif</p>
                                                <ul className={"profil_content_list_objectif"}>


                                                    {
                                                        goal[0].filter(t => t.main === true).map(((m, index) => (
                                                            <>
                                                                {

                                                                    <li key={index}><p
                                                                        className={'profil_content_text_goal'}>{m.name} - {moment(m.date).format("L")}</p>
                                                                    </li>
                                                                }
                                                            </>
                                                        )))
                                                    }

                                                </ul>
                                            </div>
                                            <IonIcon icon={chevronForward} className={"profil_content_icon"}/>
                                        </div>
                                    </li>
                                </div>

                                <div
                                    onClick={isVip.isVip === true ? () => history.push("/modifyInterObj") : () => setShowAlert(true)}>
                                    <li className={"profil_content_list_item"}>
                                        <div className={"profil_content_box"}>
                                            <img src={ObjInter} alt={"objectif intermediaire"}
                                                 className={"profil_content_icon"}/>
                                            <div className="profil_content_text_box">
                                                <p className={'profil_content_text'}>Objectif Intermédiaire</p>
                                                <ul className={"profil_content_list_objectif"}>
                                                    {
                                                        goal[0].filter(t => t.main === false).map(((m, index) => (
                                                            <>
                                                                {
                                                                    <li key={index}><p
                                                                        className={'profil_content_text_goal'}>{m.name} - {moment(m.date).format("L")}</p>
                                                                    </li>

                                                                }
                                                            </>

                                                        )))
                                                    }

                                                </ul>
                                            </div>
                                            <IonIcon icon={chevronForward} className={"profil_content_icon"}/>
                                        </div>
                                    </li>
                                </div>

                                <li className={"profil_content_list_item"}>
                                    <div className={"profil_content_box"}>
                                        <img src={Nbseance} alt={"nombre de seance"} className={"profil_content_icon"}/>
                                        <div className="profil_content_text_box">
                                            <p className={'profil_content_text'}>Nombres de séances</p>


                                            {!isEmpty(disponibility.disponibility) && (
                                                <p className={'profil_content_text_goal'}>{disponibility.disponibility.numberOfRaces}
                                                    &nbsp;séances par semaine</p>
                                            )}

                                        </div>
                                        <IonIcon onClick={() => setShowModal(!showModal)}
                                                 icon={showModal ? chevronDown : chevronForward}
                                                 className={"profil_content_icon"}/>
                                    </div>
                                    {
                                        showModal && (
                                            <>
                                                {
                                                    !isEmpty(training) && (
                                                        <ProfilModal currentDisponibility={disponibility.disponibility}
                                                                     showModal={showModal}
                                                                     setShowModal={setShowModal}
                                                                     numberOfRaces={parseInt(disponibility.disponibility.numberOfRaces)}/>
                                                    )
                                                }
                                            </>
                                        )


                                    }
                                </li>
                                <li className={"profil_content_list_item"}>
                                    <div className={"profil_content_box"}>
                                        <img src={connect} alt={"applications connectés"}
                                             className={"profil_content_icon"}/>
                                        <div className="profil_content_text_box">
                                            <p className={'profil_content_text'}>Applications Connectées</p>
                                            <p className={'profil_content_text_goal'}>Strava</p>
                                        </div>
                                        <IonIcon icon={chevronForward} className={"profil_content_icon"}/>
                                    </div>
                                </li>
                                {/*{*/}
                                {/*    Capacitor.getPlatform() === "ios" || Capacitor.getPlatform() ==="android" &&(*/}
                                <li className={"profil_content_list_item"}>
                                    <div className={"profil_content_box"}>
                                        <img src={euro} alt={"applications connectés"}
                                             className={"profil_content_icon"}/>
                                        <div className="profil_content_text_box">
                                            <p className={'profil_content_text'}>Abonnement</p>


                                            {
                                                isVip.isVip === true && isVip.isTrialPeriod === true ?
                                                    <p className={'profil_content_text_goal'}>
                                                        {`Essai gratuit jusqu'au ${moment(currentUser.trialTime).format(("LL"))}`},
                                                        puis 7.99€/mois. </p> : ""

                                            }
                                            {
                                                isVip.isVip === true && isVip.isTrialPeriod === false ?
                                                    <p className={'profil_content_text_goal'}>Abonné</p>
                                                    : ""
                                            }
                                            {
                                                isVip.isVip === false && isVip.isTrialPeriod === false ?
                                                    <Shop/> : ""

                                            }

                                        </div>
                                        <IonIcon icon={chevronForward} className={"profil_content_icon"}/>
                                    </div>
                                </li>
                                {/*    )*/}
                                {/*}*/}

                                <li className={"profil_content_list_item"}>
                                    <div className={"profil_content_box"} onClick={() => history.push("/settings")}>
                                        <img src={settings} alt={"paramètres"} className={"profil_content_icon"}/>
                                        <div className="profil_content_text_box">
                                            <p className={'profil_content_text'}>Paramètres</p>
                                        </div>
                                        <IonIcon icon={chevronForward} className={"profil_content_icon"}/>
                                    </div>
                                </li>
                                <li className={"profil_content_list_item_support"}>
                                    <p> Besoin d’aide ? Ecris-nous à </p><a
                                    onClick={() => {
                                        EmailComposer.open(email)
                                    }}><b>support@flit-sport.fr</b></a>
                                </li>
                            </ul>


                            {/*<button onClick={()=>dispatch(GetisVip({isVip: true, isTrialPeriod: false}))}>ok</button>*/}
                            {/*}>okokok*/}
                            {/*</button>*/}

                        </div>
                        <ModalNotVip setShowAlert={setShowAlert} showAlert={showAlert}/>


                        <IonAlert
                            mode={Capacitor.getPlatform() === 'ios' ? "ios" : "md"}
                            isOpen={showDcAlert}
                            onDidDismiss={() => {
                                setShowDcAlert(false);
                            }}
                            header="Deconnexion"
                            message="Vous êtes sur le point de vous déconnecter !"
                            buttons={[
                                {
                                    text: "Annuler",
                                    role: "cancel",
                                    handler: () => {
                                        setShowDcAlert(false);
                                    },
                                },
                                {
                                    text: "OK",
                                    role: "confirm",
                                    handler: () => {
                                        setShowDcAlert(false);
                                        history.push("/connexion");
                                        dispatch(logOut())
                                    },
                                },
                            ]}
                        />

                    </>)
            }

        </div>
    );
};

export default Profil;

