import React, {useEffect, useState} from "react";

import {useParams} from "react-router";
import {useSelector} from "react-redux";
import TrainingDayPlan from "./content/TrainingDayPlan";
import SkeletonDayPlan from "./skeleton/SkeletonDayPlan";
import {
    IonContent,
    IonHeader,
    IonToolbar,
    IonPage,
    IonBackButton,
    IonButtons, IonText, IonFabButton, IonIcon, IonFab, IonLoading,
} from "@ionic/react";
import {isEmpty} from "../../../../Utils/Utils";
import Star from "../trainingWeekPlan/content/Star";
import "./day.css"
import {stopwatchOutline} from "ionicons/icons";
import Minuteur from "./content/Minuteur/Minuteur";
import {Capacitor} from "@capacitor/core";

const Day = () => {
    const {day} = useParams();
    const training = useSelector((state) => state.training);
    const [repeat, setRepeat] = useState()
    const count = useSelector(state => state.count.value)
    const [currentDay, setCurrenDay] = useState(training[count].Plan.Plan.filter((t) => t.day === day))
    const [isOpen, setIsOpen] = useState(false)
    const [showLoading, setShowLoading] = useState(false)





    // const currentDay = undefined

    return (
        <IonPage>
            <IonHeader class="ion-no-border">
                <IonToolbar mode="ios" className="app_toolbar ">
                    <div className="day_toolbar">
                        <IonButtons className="day_buttons_header" slot="start">
                            <IonBackButton
                                defaultHref="/tab1"
                                text=""
                                className="headerBackButton"
                            />
                        </IonButtons>

                        <div className="day_global_title">
                            <div className="day_title_content">


                                <div className="day_star_content">
                                    <h3 className="day">{day.charAt(0).toUpperCase() + day.substr(1)}</h3>
                                    {
                                        !isEmpty(currentDay) && (currentDay.map((c,index) => (
                                            <>
                                                {
                                                    c.session_information.session_basic_data === undefined ? "" :
                                                        <>
                                                            <Star score={c.session_information.session_basic_data.score}/>


                                                            <IonText>
                                                                <p key={index} className="pourcentage">{c.session_information.session_basic_data.score}</p>
                                                            </IonText>
                                                        </>

                                                }


                                            </>
                                        )))
                                    }
                                </div>
                            </div>

                            {
                                !isEmpty(currentDay) && (
                                    <div
                                        className={isEmpty(currentDay[0].session_information.session_basic_data) ? "" : "avoid-header"}></div>
                                )
                            }

                        </div>


                        <div className={"day_empty_div"}><span></span></div>
                    </div>
                </IonToolbar>
            </IonHeader>
            <>
                {isEmpty(currentDay) ? (
                    <SkeletonDayPlan/>
                ) : (
                    <>
                        <TrainingDayPlan count={0} day={day} currentDay={currentDay} repeat={repeat}/>

                    </>
                )}
            </>
            <IonLoading
                cssClass='my-custom-class'
                mode={Capacitor.getPlatform() === "ios" ? "ios" : "md"}
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Chargement...'}
            />


        </IonPage>
    );
};

export default Day;
