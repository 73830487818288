import {
    IonCheckbox,
    IonLabel,
    IonHeader,
    IonPage,
    IonToolbar,
    IonButtons,
    IonContent,
    IonBackButton,
    IonButton,
    IonText,
    IonSpinner,
    IonAlert
} from "@ionic/react";
import axios from "axios";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router";
import {trainingWeekPlan} from "../../../../assets/db";
import {updateDisponibility} from "../../../../redux/slices/disponibility/disponibility";
import "../../../Reusable/disponibility.css"
import "./weekBilanDisponibility.css";
import {isEmpty} from "../../../../Utils/Utils";
import {emptyTraining, getShowModal, getTraining} from "../../../../redux/slices/trainingData/trainingDataSlice";
import {ChangeLogin} from "../../../../redux/slices/currentUser/currentUserSlice";
import {forEachTraining} from "../../../../API/Training/GetTraining";
import {Capacitor} from "@capacitor/core";
import {PlanGenerate} from "../../../../API/Training/PlanGenerate";

const Dispo = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [week, setWeek] = useState();
    const {numberOfRaces} = useParams();
    const answer = useSelector((state) => state.newPlan);

    const currentUser = useSelector((state) => state.currentUser);
    const training = useSelector((state) => state.training);
    const currentDisponibility = useSelector(state => state.disponibility);
    const user = useSelector(state => state.currentUser)
    const [disponibilityData, setDisponibilityData] = useState();
    const [spinner, setSpinner] = useState(false)
    const [showAlert, setShowAlert] = useState(false)


    useEffect(() => {
        if (moment().week() === 52 || moment().week() === 53) {
            let addi = moment().year() + 1
            setWeek(`${addi}01`)
        } else {
            if (moment() > moment().endOf("isoWeek").hour(8).minute(0).second(0)) {
                if (moment().week() < 10) {
                    let parse = `${moment().year()}0${moment().week() + 1}`;
                    setWeek(parse)
                } else {
                    setWeek(moment().year() + "" + (moment().week() + 1));
                }
            } else {
                if (moment().week() < 10) {
                    let parse = `${moment().year()}0${moment().week()}`;
                    setWeek(parse)
                } else {
                    setWeek(moment().year() + "" + moment().week());
                }

            }
        }


        axios
            .post(`${process.env.REACT_APP_STAGING_V2}/disponibility/foreach`, {
                debug: false, filter: [{
                    field: "userId", comparator: "==", operand: [[currentUser.id]],
                },], fields: [],
            }, {
                headers: {
                    Authorization: currentUser.token,
                },
            })
            .then((res) => {
                if (res.status === 200 || res.status === 204) {
                    setDisponibilityData({

                        monday: res.data.Data[0].monday,
                        tuesday: res.data.Data[0].tuesday,
                        wednesday: res.data.Data[0].wednesday,
                        thursday: res.data.Data[0].thursday,
                        friday: res.data.Data[0].friday,
                        saturday: res.data.Data[0].saturday,
                        sunday: res.data.Data[0].sunday
                    })
                    dispatch(updateDisponibility({id: res.data.Data[0].id, disponibility: res.data.Data[0]}))

                } else {

                }
            })
            .catch((err) => {
            });
    }, []);

    const handleSubmit = () => {
        dispatch(ChangeLogin({isLoggin: false, isRegister: true}))
        axios
            .post(`${process.env.REACT_APP_STAGING_V2}/disponibility/update`, {
                debug: false, data: {
                    numberOfRaces: currentDisponibility.disponibility.numberOfRaces,
                    id: currentDisponibility.id,
                    userId: currentUser.userId,
                    monday: disponibilityData.monday,
                    tuesday: disponibilityData.tuesday,
                    wednesday: disponibilityData.wednesday,
                    thursday: disponibilityData.thursday,
                    friday: disponibilityData.friday,
                    saturday: disponibilityData.saturday,
                    sunday: disponibilityData.sunday,
                    week: parseInt(week),
                },
            }, {
                headers: {
                    Authorization: currentUser.token,
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    setTimeout(() => {
                        axios
                            .post(
                                `${process.env.REACT_APP_STAGING_V2}/plan/generate`,
                                {
                                    debug: false,
                                    data: {
                                        id: currentUser.id,
                                        answerOne: answer.question1,
                                        answerTwo: answer.question2,
                                    },
                                    args: {},
                                },
                                {
                                    headers: {
                                        authorization: currentUser.token,
                                    },
                                }
                            )
                            .then((res) => {
                                if (res.status === 200) {
                                    dispatch(getShowModal({showModal: false, afterRecalcul: true}))

                                    dispatch(forEachTraining(currentUser))
                                    setTimeout(() => {
                                        history.push("/tab1")
                                        setSpinner(false)
                                    }, 3000)
                                }

                            })
                            .catch((err) => {
                                console.log(err)
                            });
                    }, 500)


                }
            })
            .catch((err) =>
                setShowAlert(true), setSpinner(false))
    };

    const handleChecked = (e) => {
        setDisponibilityData({
            ...disponibilityData, [e.target.name]: e.target.checked,
        });
    };

    return (<IonPage>
        <IonHeader class="ion-no-border">
            <IonToolbar mode="ios" className="app_toolbar">
                <IonButtons>
                    <IonBackButton
                        text=""
                        defaultHref="/weekbilanvolume"
                        className="app_backButtons"
                    ></IonBackButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>

            {!isEmpty(disponibilityData) && (
                <div className="weekBilanDisponibility_modal">
                    <IonText className="weekBilandisponibility_title">
                        <h2>Jours d’entraînement</h2>
                    </IonText>

                    <ul className="disponibility_list weekBilan_list">
                        <li className="disponibility_list_item">
                            <IonLabel className="disponibility_list_label">L</IonLabel>
                            <span
                                className={disponibilityData.monday ? "disponibility_checkbox_box_checked" : "disponibility_checkbox_box"}
                            >
                <IonCheckbox
                    onIonChange={(e) => {
                        handleChecked(e);
                    }}
                    type="checkbox"
                    name="monday"
                    className="disponibility_list_item_checkbox"
                    defaultChecked={disponibilityData.monday}
                    checked={disponibilityData.monday}

                />
              </span>
                        </li>
                        <li className="disponibility_list_item">
                            <IonLabel className="disponibility_list_label">M</IonLabel>
                            <span
                                className={disponibilityData.tuesday ? "disponibility_checkbox_box_checked" : "disponibility_checkbox_box"}
                            >
                <IonCheckbox
                    onIonChange={(e) => {
                        handleChecked(e);
                    }}
                    type="checkbox"

                    name="tuesday"
                    className="disponibility_list_item_checkbox"
                    defaultChecked={disponibilityData.tuesday}
                    checked={disponibilityData.tuesday}
                />
              </span>
                        </li>
                        <li className="disponibility_list_item">
                            <IonLabel className="disponibility_list_label">M</IonLabel>
                            <span
                                className={disponibilityData.wednesday ? "disponibility_checkbox_box_checked" : "disponibility_checkbox_box"}
                            >
                <IonCheckbox
                    onIonChange={(e) => {
                        handleChecked(e);
                    }}
                    type="checkbox"
                    name="wednesday"
                    className="disponibility_list_item_checkbox"
                    defaultChecked={disponibilityData.wednesday}
                    checked={disponibilityData.wednesday}
                />
              </span>
                        </li>
                        <li className="disponibility_list_item">
                            <IonLabel className="disponibility_list_label">J</IonLabel>
                            <span
                                className={disponibilityData.thursday ? "disponibility_checkbox_box_checked" : "disponibility_checkbox_box"}
                            >
                <IonCheckbox
                    onIonChange={(e) => {
                        handleChecked(e);
                    }}
                    type="checkbox"
                    name="thursday"
                    className="disponibility_list_item_checkbox"
                    defaultChecked={disponibilityData.thursday}
                    checked={disponibilityData.thursday}
                />
              </span>
                        </li>
                        <li className="disponibility_list_item">
                            <IonLabel className="disponibility_list_label">V</IonLabel>
                            <span
                                className={disponibilityData.friday ? "disponibility_checkbox_box_checked" : "disponibility_checkbox_box"}
                            >
                <IonCheckbox
                    onIonChange={(e) => {
                        handleChecked(e);
                    }}
                    type="checkbox"
                    name="friday"
                    className="disponibility_list_item_checkbox"
                    defaultChecked={disponibilityData.friday}
                    checked={disponibilityData.friday}
                />
              </span>
                        </li>
                        <li className="disponibility_list_item">
                            <IonLabel className="disponibility_list_label">S</IonLabel>
                            <span
                                className={disponibilityData.saturday ? "disponibility_checkbox_box_checked" : "disponibility_checkbox_box"}
                            >
                <IonCheckbox
                    onIonChange={(e) => {
                        handleChecked(e);
                    }}
                    type="checkbox"
                    name="saturday"
                    className="disponibility_list_item_checkbox"
                    defaultChecked={disponibilityData.saturday}
                    checked={disponibilityData.saturday}
                />
              </span>
                        </li>
                        <li className="disponibility_list_item">
                            <IonLabel className="disponibility_list_label">D</IonLabel>
                            <span
                                className={disponibilityData.sunday ? "disponibility_checkbox_box_checked" : "disponibility_checkbox_box"}
                            >
                <IonCheckbox
                    onIonChange={(e) => {
                        handleChecked(e);
                    }}
                    type="checkbox"
                    name="sunday"
                    className="disponibility_list_item_checkbox"
                    defaultChecked={disponibilityData.sunday}
                    checked={disponibilityData.sunday}
                />
              </span>
                        </li>
                    </ul>

                    <IonButton
                        onClick={() => {
                            handleSubmit();
                            setSpinner(true)
                        }}
                        className="weekBilanDisponibility_button"
                    >
                        {
                            spinner ? <IonSpinner name="crescent"/> :
                                "Démarrer ma semaine 🏃"}
                    </IonButton>
                </div>)}

            <IonAlert
                mode={Capacitor.getPlatform() === 'ios' ? "ios" : "md"}
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header="Une erreur est survenue !"
                message="Réessaie ta génération plus tard"
                buttons={['OK']}
            />
        </IonContent>
    </IonPage>);
};

export default Dispo;
