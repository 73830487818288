import {IonAlert} from "@ionic/react";
import {buyPackage} from "../../components/App/Profil/shops";
import React, {useEffect} from "react";
import {Capacitor} from "@capacitor/core";
import {useIonAlert} from "@ionic/react";


export const ApiError = (error) => {

   if(error.status ===404){
      return alert(`${error.message}, Tu es bien connecté à internet ?`)
   }
   if(error.status ===400){
      return alert(`${error.message}`)
   }


}


