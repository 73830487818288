import axios from "axios";
import {ChangeLogin} from "../../redux/slices/currentUser/currentUserSlice";
import {emptyTraining, getShowModal, getTraining} from "../../redux/slices/trainingData/trainingDataSlice";
import moment from "moment/moment";

export const forEachTraining = (data) => async (dispatch) => {

    // this function is for the endof weekModal
    const showTheModal = async (temporalId) => {

        // temporalId = temporalPlanId in trainingWeekPlan, see the reference below ↓↓↓
        // get the week number with the temporalPlanId, have to split the temporalPlanId
        // use moment js to have the date (sunday of the temporalPlanId week  at 8 am)
        const actualSunday = await moment()
            .year(
                parseInt(temporalId.toString().split("")[0] + "" + temporalId.toString().split("")[1] + "" + temporalId.toString().split("")[2] + "" + temporalId.toString().split("")[3])
            )
            .isoWeek(parseInt(temporalId.toString().split("")[4] + "" + temporalId.toString().split("")[5]))
            .endOf("week")
            .hour(8)
            .minute(0)
            .second(0);


        // if current time is after actualSunday , update redux for showModal
        if (moment() > actualSunday) {
            dispatch(getShowModal({showModal: true, afterRecalcul: false}))
        } else {
            dispatch(getShowModal({showModal: false}))
        }


    }


    // Api Calls -- get trainingweekPlan
    await axios
        .post(
            `${process.env.REACT_APP_STAGING_V2}/trainingweekplan/foreach`,
            {
                debug: false,
                filter: [
                    {
                        field: "userId",
                        comparator: "==",
                        operand: [[data.id]],
                    },
                ],
                fields: [],
            },
            {
                headers: {
                    authorization: data.token,
                },
            }
        )
        .then((res) => {
            if (res.status === 400 || res.data.error || res.status === 204) {
                dispatch(ChangeLogin({isLoggin: false, isRegister: true}))
            } else {
                    // emptytraining is for clear the state of trainingweekplan
                    dispatch(emptyTraining())
                    // update the redux with the response
                    dispatch(getTraining(res.data.Data))
                    // put the temporalPlanId in showModam function
                    showTheModal(res.data.Data[0].Plan.TemporalPlanId)

                    // this redux state is use for the loading screen for the trainingWeeKPlan , can be updated ⚠⚠
                    dispatch(ChangeLogin({isLoggin: true, isRegister: false}))

            }
        })
        .catch((err) => console.log(err));


};
