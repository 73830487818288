import {IonContent, IonImg, IonPage, IonText, IonAlert} from "@ionic/react";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import logoFlit from "../../../../assets/FormulaireAssets/Ola como estas.gif";
import {getShowModal, getTraining} from "../../../../redux/slices/trainingData/trainingDataSlice";
import "./weekBilanSuccess.css";
import {Capacitor} from "@capacitor/core";

const WeekBilanSuccess = () => {
        const history = useHistory();
        const [showAlert, setShowAlert] = useState(false);
        const answer = useSelector((state) => state.newPlan);
        const showModal = useSelector(state => state.showModal);
        const dispatch = useDispatch();
        const [test, setTest] = useState({
            text1: true,
            text2: false,
            text3: false,
            text4: false,
        });
        const currentUser = useSelector((state) => state.currentUser);


        useEffect(() => {



            setTimeout(() => {
                if (test.text1 === true) {
                    setTest({
                        ...test,
                        text1: false,
                        text2: true,
                        text3: false,
                        text4: false,
                    });
                }
                if (test.text2 === true) {
                    setTest({
                        ...test,
                        text1: false,
                        text2: false,
                        text3: true,
                        text4: false,
                    });
                }
                if (test.text3 === true) {
                    setTest({
                        ...test,
                        text1: false,
                        text2: false,
                        text3: false,
                        text4: true,
                    });
                }
                if (test.text4 === true) {
                    setTest({
                        ...test,
                        text1: true,
                        text2: false,
                        text3: false,
                        text4: false,
                    });
                }
            }, 5000);
        }, [test]);

        return (
            <IonPage>
                <IonContent>
                    <div className="weekBilanSuccess">
                        <IonImg className="weekBilanSuccess_logo" src={logoFlit}/>

                        <div
                            className={
                                test.text1
                                    ? "weekBilanSuccess_textContent"
                                    : "weekBilanSuccess_none"
                            }
                        >
                            <IonText className="weekBilanSuccess_subTitle">
                                <h2> Récupération de tes données…</h2>
                            </IonText>
                            <IonText>
                                <p>
                                    {" "}
                                    On importe toutes tes courses pour savoir ce dont tu es capable.
                                </p>
                            </IonText>
                        </div>

                        <div
                            className={
                                test.text2
                                    ? "weekBilanSuccess_textContent"
                                    : "weekBilanSuccess_none"
                            }
                        >
                            <IonText className="weekBilanSuccess_subTitle">
                                <h2> Analyse de ton profil…</h2>
                            </IonText>
                            <IonText>
                                <p> On évalue ton niveau et ton potentiel.</p>
                            </IonText>
                        </div>
                        <div
                            className={
                                test.text3
                                    ? "weekBilanSuccess_textContent"
                                    : "weekBilanSuccess_none"
                            }
                        >
                            <IonText className="weekBilanSuccess_subTitle">
                                <h2> Structuration du plan…</h2>
                            </IonText>
                            <IonText>
                                <p> On prépare ton calendrier en fonction de ton objectif.</p>
                            </IonText>
                        </div>
                        <div
                            className={
                                test.text4
                                    ? "weekBilanSuccess_textContent"
                                    : "weekBilanSuccess_none"
                            }
                        >
                            <IonText className="weekBilanSuccess_subTitle">
                                <h2> Génération de la première semaine…</h2>
                            </IonText>
                            <IonText>
                                <p> Chaque semaine des séances variées à découvrir !</p>
                            </IonText>
                        </div>
                    </div>
                    <IonAlert
                        mode={Capacitor.getPlatform() === 'ios' ? "ios" : "md"}
                        isOpen={showAlert}
                        onDidDismiss={() => {
                            setShowAlert(false);
                            history.push("/tab1");
                        }}
                        header="Oops"
                        message="Il y a eu un problème avec le calcul de ton plan d'entraînement. Contacte-nous pour en savoir un peu plus"
                        buttons={["OK"]}
                    />
                </IonContent>
            </IonPage>
        );
    }
;

export default WeekBilanSuccess;
