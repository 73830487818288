import React, {useEffect, useState} from "react";
import {IonContent, IonPage, IonText, isPlatform, useIonAlert} from "@ionic/react";
import * as BsIcons from "react-icons/bs";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {getStrava} from "../../../../redux/slices/strava/stravaSlice";
import {useHistory} from "react-router";
import "./stravaSuccess.css"

const StravaSuccess = () => {
    const user = useSelector((state) => state.currentUser);
    const strava = useSelector((state) => state.strava);
    const dispatch = useDispatch();
    const history = useHistory();
    const [timer, setTimer] = useState(5)
    const [presentAlert]= useIonAlert()
    const [showAlert,setShowAlert] = useState(false)


    useEffect(() => {



        if(window.location.href.includes("error=access_denied")){
            history.push("/connect")

        }
        if (isPlatform("desktop") || isPlatform('mobileweb')) {
            const codeData = window.location.href.split("code=").pop().split("&scope")[0];


            axios
                .post(`${process.env.REACT_APP_STAGING_V2_STRAVA}/strava/getuserdata`, {
                    debug: false,
                    data: {
                        userId: user.id,
                        code: codeData
                    },
                    args: {}
                }, {
                    headers: {
                        Authorization: user.token
                    }
                })
                .then((res) => {
                    if (res.status === 204) {
                        history.push("/noviceLevel")
                    }
                    if (res.status === 200) {
                        history.push("/disponibilityWeek");
                    }
                    if(res.status === 409){
                        history.push("/connect")
                    }

                })
                .catch((err) => console.error(err));


        }
    }, [dispatch, history]);

    return (
        <IonPage>
            <IonContent>
                <div className="stravaSuccess">
                    <div className="stravaSuccess_content">

                        <BsIcons.BsFillCheckCircleFill className="stravaSuccess_content_logo"/>

                        <IonText className="stravaSuccess_subTitle">
                            <p> Synchronisation réussie</p>
                        </IonText>
                        <IonText><p>Redirection dans {timer} secondes ...</p></IonText>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default StravaSuccess;
