import React from 'react';
import { IonContent, IonHeader, IonRefresher, IonRefresherContent, IonTitle, IonToolbar, RefresherEventDetail } from '@ionic/react';

function RefreshSpinner() {

    function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
        setTimeout(() => {
            // Any calls to load data go here

            event.detail.complete();
        }, 2000);
    }

    return (
        <>

                <IonRefresher onIonRefresh={handleRefresh}>
                    <IonRefresherContent>
                        <button >ok</button>
                    </IonRefresherContent>
                </IonRefresher>


        </>
    );
}
export default RefreshSpinner;